.our_team_main_container {
}
.our_team_under_container {
  background: url("../../assets//aboutus/bg-team1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  background-attachment: scroll;
  background-origin: border-box;
}
.meet_the_team_bg_container {
  margin: 0 auto;
}

.team_all_content_containert {
  color: var(--theme-white);
  text-align: center;
}

.sub_text_title_text {
  font-size: 25px;
  font-family: "OswaldMed";
  line-height: 30px;
}
.our_team_title_text {
  font-size: 45px;
  font-family: "OswaldMed";
  line-height: 50px;
  margin: 15px 0;
}
.extra_team_word_text {
  font-size: 14px;
  font-family: "poppinsLight";
  line-height: 22px;
  width: 37%;
  margin: 0 auto;
}
.team_photo_slider_container {
  margin: 50px auto 0;
}
.photo_team_container {
  position: relative;
  width: 90%;
  height: 385px;
}
.person_photo_self {
  width: 100%;
}
.name_and_subname_container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(222, 222, 222, 0.264) 0%,
    rgba(222, 222, 222, 0.44) 100%
  );
  padding: 13px 10px;
  color: var(--theme-white);
  text-shadow: 0px 2px 1px rgb(63 56 56 / 58%);
}
.name_photo_text {
  font-family: "PoppinsSemiBold";
  font-size: 18px;
  line-height: 22px;
}
.sub_text_photo_text {
  font-family: "PoppinsRegular";
  font-size: 18px;
  line-height: 22px;
}
.btn_group_container {
  margin: 20px 0;
}

@media (max-width: 299px) {
  .team_photo_slider_container {
    margin: 35px 0 0;
    width: 100%;
  }
  .our_team_under_container {
    margin: 0 auto;
    padding: 30px 0;
    background-position: inherit;
    background-size: cover;
    background-attachment: scroll;
  }
  .sub_text_title_text {
    font-size: 20px;

    line-height: 25px;
  }
  .our_team_title_text {
    font-size: 34px;

    line-height: 40px;
    margin: 10px 0;
  }
  .extra_team_word_text {
    font-size: 13px;

    line-height: 20px;
    width: 93%;
    margin: 0 auto;
  }
  .photo_team_container {
    position: relative;
    width: 94%;
    height: 298px;
  }
  .name_photo_text {
    font-size: 15px;
    line-height: 20px;
  }
  .sub_text_photo_text {
    font-size: 15px;
    line-height: 20px;
  }
  .name_and_subname_container {
    padding: 7px 8px;
  }
}

@media (min-width: 300px) and (max-width: 399px) {
  .our_team_main_container {
    padding: 0 0 30px;
  }
  .sub_text_photo_text {
    font-size: 15px;
    line-height: 21px;
  }
  .name_photo_text {
    font-size: 16px;
    line-height: 22px;
  }
  .team_photo_slider_container {
    margin: 10px auto 0;
    width: 280px;
  }
  .meet_the_team_bg_container {
    padding: 30px 0;
  }
  .our_team_under_container {
    background-position: center;
    background-size: cover;
    background-attachment: scroll;
  }
  .photo_team_container {
    position: relative;
    width: 94%;
    height: 295px;
  }
  .extra_team_word_text {
    font-size: 14px;
    font-family: "poppinsLight";
    line-height: 22px;
    width: 94%;
    margin: 0 auto;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .our_team_title_text {
    font-size: 40px;

    line-height: 50px;
    margin: 15px 0;
  }
  .our_team_main_container {
    padding: 0 0 30px;
  }
  .sub_text_photo_text {
    font-size: 15px;
    line-height: 21px;
  }
  .name_photo_text {
    font-size: 16px;
    line-height: 22px;
  }
  .team_photo_slider_container {
    margin: 10px auto 0;
    width: 380px;
  }
  .meet_the_team_bg_container {
    padding: 30px 0;
    width: 380px;
  }
  .our_team_under_container {
    background-position: center;
    background-size: cover;
    background-attachment: scroll;
  }
  .photo_team_container {
    position: relative;
    width: 94%;
    height: 380px;
  }
  .extra_team_word_text {
    font-size: 16px;

    line-height: 24px;
    width: 94%;
    margin: 0 auto;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .meet_the_team_bg_container {
    width: 700px;
    padding: 35px 0;
  }
  .team_photo_slider_container {
    margin: 35px auto 0;
    width: 700px;
  }
  .our_team_under_container {
  }

  .our_team_title_text {
    font-size: 40px;

    margin: 10px 0;
  }
  .extra_team_word_text {
    font-size: 15px;

    line-height: 24px;
    width: 60%;
  }
  .our_team_under_container {
    margin: 0 auto;
    padding: 35px 0;

    background-size: cover;
    background-position: center;
    background-attachment: scroll;
    background-origin: border-box;
  }
  .photo_team_container {
    position: relative;
    width: 90%;
    height: 365px;
  }

  .name_photo_text {
    font-size: 20px;
    line-height: 26px;
  }
  .sub_text_photo_text {
    font-size: 16px;
    line-height: 20px;
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  .meet_the_team_bg_container {
    width: 930px;
    padding: 40px 0;
  }
  .team_photo_slider_container {
    margin: 0px auto 0;
    width: 930px;
  }
  .sub_text_title_text {
    font-size: 30px;

    line-height: 40px;
  }
  .our_team_title_text {
    font-size: 45px;
    line-height: 55px;
    margin: 15px 0;
  }
  .extra_team_word_text {
    font-size: 17px;
    line-height: 28px;
    width: 80%;
  }
  .photo_team_container {
    position: relative;
    width: 87%;
    height: 320px;
  }
  .name_photo_text {
    font-size: 17px;
    line-height: 28px;
  }
}

@media (min-width: 1200px) {
  .our_team_under_container {
    margin: 0 auto;
    padding: 60px 0 40px;
    background-size: cover;

    background-position: center;
    background-attachment: scroll;
    background-origin: border-box;
  }
  .team_photo_slider_container {
    margin: 50px auto 0;
    width: 1100px;
  }

  .extra_team_word_text {
    font-size: 18px;

    line-height: 28px;
    width: 50%;
    margin: 0 auto;
  }
  .sub_text_title_text {
    font-size: 30px;

    line-height: 31px;
  }
  .our_team_title_text {
    font-size: 55px;

    line-height: 60px;
    margin: 19px 0;
  }
  .meet_the_team_bg_container {
    width: 1100px;
  }
  .photo_team_container {
    position: relative;
    width: 86%;
    height: 370px;
  }
}

@media (min-width: 1400px) {
  .meet_the_team_bg_container {
    width: 1300px;
  }
  .photo_team_container {
    position: relative;
    width: 82%;
    height: 420px;
  }
  .team_photo_slider_container {
    width: 1300px;
  }
}
