.idea_main_container {
  background-color: var(--theme-white);
}
.idea_under_main_container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;

  justify-content: space-between;
}
.left_idea_container {
  width: 50%;
  /* background-color:red; */
}
.right_idea_container {
  width: 50%;
  /* background-color:rgb(0, 162, 255); */
}
.left_img_container {
  width: 500px;
  height: 546px;
}
.idea_left_img {
  width: 100%;
}
.txt_title_idea_left_container {
}
.text_title {
  font-size: 66px;
  font-family: "OswaldSemiBold";
  line-height: 70px;
  text-transform: uppercase;
  color: var(--section-black);
}
.sub_text_title {
  color: var(--theme-green);
  text-transform: uppercase;
}
.one_square_right_container {
  background-color: var(--theme-green);
  width: 400px;
  height: 495px;
  position: absolute;
  z-index: 0;
  top: 85px;
  left: 95px;
}
.two_sqaure_img_contianer {
  position: relative;
  margin: 120px 0 0 0;
}

.right_img_container {
  z-index: 1;
  position: relative;
  width: 430px;
  height: 525px;
}
.idea_extra_content {
  color: var(--section-black);
  font-family: PoppinsRegular;
  font-size: 20px;
  line-height: 26px;
  width: 80%;
  letter-spacing: 1px;
  margin: 25px 0;
}

@media (max-width: 299px) {
  .idea_under_main_container {
    width: 94%;
    margin: 0 auto;
    padding: 35px 0;
  }
  .idea_extra_content {
    font-size: 14px;
    line-height: 19px;
    width: 100%;
    letter-spacing: 1px;
    margin: 9px 0 0;
  }
  .idea_under_main_container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
  }
  .two_sqaure_img_contianer {
    position: relative;
    margin: 40px 0 0 0;
    display: none;
  }
  .left_idea_container,
  .right_idea_container {
    width: 100%;
  }
  .left_img_container {
    width: 100%;
    height: 100%;
  }
  .text_title {
    font-size: 30px;

    line-height: 35px;
  }
}

@media (min-width: 300px) and (max-width: 399px) {
  .idea_under_main_container {
    width: 280px;
    margin: 0 auto;
    padding: 35px 0;
  }
  .idea_extra_content {
    font-size: 14px;
    line-height: 19px;
    width: 100%;
    letter-spacing: 1px;
    margin: 9px 0 0;
  }
  .idea_under_main_container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
  }
  .two_sqaure_img_contianer {
    position: relative;
    margin: 40px 0 0 0;
    display: none;
  }
  .left_idea_container,
  .right_idea_container {
    width: 100%;
  }
  .left_img_container {
    width: 100%;
    height: 100%;
  }
  .text_title {
    font-size: 30px;

    line-height: 35px;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .txt_title_idea_left_container {
    margin: 20px 0 0;
  }
  .idea_under_main_container {
    width: 380px;
    margin: 0 auto;
    padding: 35px 0;
  }
  .idea_extra_content {
    font-size: 17px;
    line-height: 22px;
    width: 100%;
    letter-spacing: 1px;
    margin: 10px 0 0;
  }
  .idea_under_main_container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
  }
  .two_sqaure_img_contianer {
    position: relative;
    margin: 40px 0 0 0;
    display: none;
  }
  .left_idea_container,
  .right_idea_container {
    width: 100%;
  }
  .left_img_container {
    width: 100%;
    height: 100%;
  }
  .text_title {
    font-size: 37px;
    line-height: 42px;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .left_img_container {
    width: 300px;
    height: 346px;
  }
  .right_img_container {
    z-index: 1;
    width: 265px;
    height: 325px;
  }
  .one_square_right_container {
    width: 210px;
    height: 285px;
    top: 80px;
    left: 100px;
  }
  .idea_under_main_container {
    width: 700px;
    margin: 0 auto;
    padding: 35px 0;
  }
  .text_title {
    font-size: 40px;

    line-height: 44px;
  }
  .idea_extra_content {
    font-size: 17px;
    line-height: 22px;
    width: 90%;
    letter-spacing: 1px;
    margin: 10px 0 15px;
  }
  .two_sqaure_img_contianer {
    position: relative;
    margin: 60px 0 0 0;
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  .left_img_container {
    width: 394px;
    height: 434px;
  }
  .right_img_container {
    width: 300px;
    height: 300px;
  }
  .one_square_right_container {
    width: 270px;
    height: 351px;
    top: 75px;
    left: 106px;
  }
  .idea_under_main_container {
    width: 930px;
    margin: 0 auto;
    padding: 35px 0;
  }
  .text_title {
    font-size: 45px;
    line-height: 49px;
  }
  .idea_extra_content {
    font-size: 18px;
    line-height: 25px;
    width: 90%;
    letter-spacing: 1px;
    margin: 10px 0 15px;
  }
  .two_sqaure_img_contianer {
    position: relative;
    margin: 125px 0 0 3px;
  }
}

@media (min-width: 1200px) {
  .idea_under_main_container {
    width: 1100px;
    margin: 0 auto;
    padding: 60px 0;
  }
  .left_img_container {
    width: 450px;
    height: 500px;
  }
  .text_title {
    font-size: 60px;
    line-height: 58px;
  }
  .idea_extra_content {
    font-size: 20px;
    line-height: 27px;
    width: 80%;
    letter-spacing: 1px;
    margin: 10px 0 0;
  }
  .right_img_container {
    z-index: 1;
    width: 360px;
    height: 360px;
  }

  .one_square_right_container {
    width: 350px;
    height: 430px;

    top: 85px;
    left: 95px;
  }
}
