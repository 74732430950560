.service_main_container {
  background-color: var(--section-black);
  width: 100%;
}
.service_box {
  width: 31.33%;
  margin: 0 0 35px;
  /* 
  background-color: rgb(54, 46, 2); */
}
.heading_text_service {
  color: var(--theme-white);
  font-family: OswaldReg;
  font-size: 30px;
}
.extra_text_service {
  color: var(--theme-white);
  font-family: PoppinsRegular;
  font-size: 20px;
  padding: 20px 0;
  margin: 0;
}
.service_under_main_container {
}
.service_all_box_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.our_service_text_title {
  font-size: 60px;
  line-height: 70px;
  margin: 0 0 37px;
  text-align: center;
  font-family: OswaldSemiBold;
  color: var(--theme-white);
}

@media (max-width: 299px) {
  .our_service_text_title {
    font-size: 30px;
    line-height: 35px;
  }
  .service_main_container {
    width: 100%;
    padding: 35px 0;
  }
  .service_box {
    width: 100%;
    margin: 0px 0 10px;
  }
  .service_under_main_container {
    width: 94%;
    margin: 0 auto;
  }
  .heading_text_service {
    font-size: 21px;
  }
  .extra_text_service {
    font-size: 14px;
    line-height: 23px;
    padding: 6px 0;
    margin: 0;
  }
}

@media (min-width: 300px) and (max-width: 399px) {
  .our_service_text_title {
    font-size: 30px;
    line-height: 35px;
  }
  .service_main_container {
    width: 100%;
    padding: 35px 0;
  }
  .service_box {
    width: 100%;
    margin: 0px 0 10px;
  }
  .service_under_main_container {
    width: 280px;
    margin: 0 auto;
  }
  .heading_text_service {
    font-size: 21px;
  }
  .extra_text_service {
    font-size: 14px;
    line-height: 23px;
    padding: 6px 0;
    margin: 0;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .our_service_text_title {
    font-size: 37px;
    line-height: 49px;
  }
  .service_main_container {
    width: 100%;
    padding: 35px 0;
  }
  .service_box {
    width: 100%;
    margin: 0px 0 10px;
  }
  .service_under_main_container {
    width: 380px;
    margin: 0 auto;
  }
  .heading_text_service {
    font-size: 23px;
  }
  .extra_text_service {
    font-size: 17px;
    line-height: 27px;
    padding: 10px 0;
    margin: 0;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .service_under_main_container {
    width: 700px;
    margin: 0 auto;
    padding: 35px 0;
  }
  .service_box {
    width: 49.33%;
    margin: 0 0 10px;
  }
  .heading_text_service {
    font-size: 25px;
  }
  .extra_text_service {
    font-size: 16px;
    line-height: 26px;
    padding: 9px 0;
    margin: 0;
  }
  .our_service_text_title {
    font-size: 45px;
    line-height: 50px;
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  .service_under_main_container {
    width: 930px;
    margin: 0 auto;
    padding: 40px 0;
  }
  .service_box {
    width: 30.33%;
    margin: 0 0 15px;
  }
  .heading_text_service {
    font-size: 25px;
  }
  .extra_text_service {
    font-size: 18px;
    line-height: 29px;
    padding: 14px 0;
    margin: 0;
  }
  .our_service_text_title {
    font-size: 45px;
    line-height: 60px;
  }
}

@media (min-width: 1200px) {
  .service_under_main_container {
    width: 1100px;
    margin: 0 auto;
    padding: 70px 0;
  }
}

@media (min-width: 1400px) {
  .service_under_main_container {
    width: 1300px;
    margin: 0 auto;
    padding: 70px 0;
  }
}
