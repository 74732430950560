.main_business_vdo_container {
  padding: 60px 0;
  background-color: var(--theme-grey);
}
.under_business_vdo_container {
  margin: 0 auto;
}
.work_flow_text_heading_about {
}
.green_vdo_container {
  margin: 60px 0 0;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 20%,
    rgba(27, 94, 32, 1) 17%
  );
}
.vdo_for_business_container {
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 75px 0 0;
}

.video_hero_section {
  width: 90%;
}
.text_btn_container_business_vdo {
  text-align: center;
  margin: 25px 0 0;
}
.business_vdo_paragraph_text {
  color: var(--section-black);
  font-family: PoppinsRegular;
  font-size: 20px;
}
.view_more_btn_business_vdo {
  font-family: "PoppinsRegular";
  width: 23%;
  font-size: 21px;
  background: var(--theme-green);
  color: #ffffffe0;
  border: 0;
  height: 57px;
  margin: 20px 0 0;
  letter-spacing: 3px;
}
.view_more_btn_business_vdo:hover {
  background: var(--section-black);
  color: #ffffff;
}

@media (max-width: 399px) {
  .main_business_vdo_container {
    padding: 30px 0;
  }
  .green_vdo_container {
    margin: 30px 0 0;
  }
  .vdo_for_business_container {
    padding: 25px 0 0;
  }
  .video_hero_section {
    width: 85%;
  }
  .text_btn_container_business_vdo {
    text-align: center;
    margin: 15px 0 0;
  }
  .business_vdo_paragraph_text {
    font-size: 13px;
    line-height: 17px;
    width: 98%;
    margin: 0 auto;
  }
  .view_more_btn_business_vdo {
    width: 50%;
    font-size: 15px;

    height: 40px;
    margin: 20px 0 0;
    letter-spacing: 3px;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .main_business_vdo_container {
    padding: 30px 0;
  }
  .green_vdo_container {
    margin: 30px 0 0;
  }
  .vdo_for_business_container {
    padding: 25px 0 0;
  }
  .video_hero_section {
    width: 85%;
  }
  .text_btn_container_business_vdo {
    text-align: center;
    margin: 15px 0 0;
  }
  .business_vdo_paragraph_text {
    font-size: 13px;
    line-height: 17px;
    width: 98%;
    margin: 0 auto;
  }
  .view_more_btn_business_vdo {
    width: 50%;
    font-size: 15px;

    height: 40px;
    margin: 20px 0 0;
    letter-spacing: 3px;
  }
  .under_business_vdo_container {
    width: 380px;
  }
}

@media (min-width: 768px) {
  .under_business_vdo_container {
    width: 700px;
  }
  .view_more_btn_business_vdo {
    width: 25%;
    font-size: 19px;

    border: 0;
    height: 55px;
    margin: 20px 0 0;
    letter-spacing: 3px;
  }
  .vdo_for_business_container {
    padding: 55px 0 0;
  }
  .green_vdo_container {
    margin: 30px 0 0;
  }
  .text_btn_container_business_vdo {
    margin: 10px 0 0;
  }
  .business_vdo_paragraph_text {
    font-size: 17px;
  }
  .view_more_btn_business_vdo {
    width: 23%;
    font-size: 16px;

    height: 50px;
    margin: 10px 0 0;
  }
}

@media (min-width: 991px) {
  .under_business_vdo_container {
    width: 900px;
  }
  .view_more_btn_business_vdo {
    width: 25%;
    font-size: 19px;

    border: 0;
    height: 55px;
    margin: 20px 0 0;
    letter-spacing: 3px;
  }
  .vdo_for_business_container {
    padding: 55px 0 0;
  }
  .green_vdo_container {
    margin: 30px 0 0;
  }
  .text_btn_container_business_vdo {
    margin: 10px 0 0;
  }
  .business_vdo_paragraph_text {
    font-size: 17px;
  }
  .view_more_btn_business_vdo {
    width: 23%;
    font-size: 16px;

    height: 50px;
    margin: 10px 0 0;
  }
}

@media (min-width: 1200px) {
  .under_business_vdo_container {
    width: 1100px;
  }
  .video_hero_section {
    width: 88%;
  }
  .green_vdo_container {
    margin: 30px auto 0;
    width: 890px;
  }
  .view_more_btn_business_vdo {
    width: 20%;
    font-size: 16px;

    height: 50px;
    margin: 10px 0 0;
  }
}
