.testimonial_main_container {
}

.testimonial_under_main_container {
  width: 100%;
}

.img_bg_heading_section {
  background-image: url("../../assets/testimonial_bg.jpg");
  background-color: #ebebeb;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-origin: border-box;
  width: 100%;
  padding: 60px 0 0;
  position: relative;
  height: 55vh;
}

.testimonial_text_heading {
  font-family: OswaldSemiBold;
  font-size: 60px;
  line-height: 70px;
  text-transform: uppercase;
  color: var(--theme-white);
  text-align: center;
}

.grey_bg_container {
  background: linear-gradient(180deg, rgb(235 235 235) 0%, #ebebeb 100%);
}

.logo_container {
  padding: 60px 0 0;
  display: flex;
  justify-content: center;
}

.logo_dwec_container {
  width: 200px;
}

.two_container_of_testimonial_section {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 1100px;
  margin: 0 auto;
  position: relative;
  /* top: -28vh; */
  background: var(--theme-white);
  margin: -15rem auto 0;
  z-index: 4;
  position: relative;
}

.left_testimonial_section {
  width: 50%;
  padding: 35px;
}

.logo_container {
}

.left_img_testimonial_container {
  /* background-color: aqua; */
}

.right_testimonial_under_content_container {
  padding: 35px 35px 0 0;
}

.text_of_testimonial_content {
  margin: 20px 0;
  text-align: center;
}

.left_right_quote_and_img_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.right_testimonial_section {
  width: 50%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    270deg,
    rgba(27, 94, 32, 0.08) 25.52%,
    rgba(112, 154, 115, 0.050219) 70.31%,
    rgba(181, 203, 182, 0.0260395) 85.42%,
    rgba(255, 255, 255, 0) 100%
  );
  justify-content: center;
}

.first_quote_img_container {
  width: 20.33%;
  display: flex;
  justify-content: center;
}
.first_quote_img_container > img {
  transform: rotate(180deg);
  width: 35px;
}

.profile_img_testimonial_container {
  width: 33.33%;
  display: flex;
  justify-content: center;
}

.name_of_user_text {
  font-family: poppinsSemiBold;
  color: var(--section-black);
  font-size: 24px;
  line-height: 27px;
}

.tagline_of_user_content {
  color: rgba(0, 0, 0, 0.25);
  font-family: poppinsSemiBold;
}

.extra_content_of_text {
  font-family: poppinsLight;
  font-size: 17px;
  font-style: italic;
  color: rgba(0, 0, 0, 0.52);
  margin: 20px 0 0;
}
.profile_img_testimonial_container > img {
  width: 70%;
}

@media (max-width: 299px) {
  .img_bg_heading_section {
    width: 100%;
    height: 58vh;
  }
  .testimonial_text_heading {
    font-size: 30px;
    line-height: 32px;
    text-align: center;
  }

  .left_testimonial_section {
    width: 0;
    padding: 35px;
    display: none;
  }
  .two_container_of_testimonial_section {
    flex-direction: column;
    width: 100%;

    position: relative;
    top: 0;

    margin: -15rem auto 0;
  }

  .right_testimonial_section {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .right_testimonial_under_content_container {
    padding: 35px 0;
  }
  .first_quote_img_container > img {
    width: 25px;
  }
  .profile_img_testimonial_container > img {
    width: 75%;
  }
  .name_of_user_text {
    font-size: 17px;
    line-height: 21px;
  }
  .tagline_of_user_content {
    font-size: 13px;
    line-height: 15px;
  }
  .extra_content_of_text {
    font-size: 13px;
    line-height: 16px;
    margin: 10px 0 0;
  }
  .name_of_user_text {
    font-size: 17px;
    line-height: 21px;
  }
  .logo_container {
    padding: 20px 0 0;
    display: flex;
    justify-content: center;
  }
  .logo_dwec_container {
    width: 60px;
  }
  .logo_container {
    padding: 20px 0 15px;
    display: flex;
    justify-content: space-evenly;
  }
}

@media (min-width: 300px) and (max-width: 399px) {
  .img_bg_heading_section {
    width: 100%;
    height: 390px;
  }
  .testimonial_text_heading {
    font-size: 30px;
    line-height: 32px;
    text-align: center;
  }

  .left_testimonial_section {
    width: 0;
    padding: 35px;
    display: none;
  }
  .two_container_of_testimonial_section {
    flex-direction: column;
    width: 290px;

    position: relative;
    top: 0;

    margin: -15rem auto 0;
  }

  .right_testimonial_section {
    width: 100%;
    display: flex;
    flex-direction: column;

    padding: 0 15px 30px;
  }
  .right_testimonial_under_content_container {
    padding: 35px 0 0;
  }
  .first_quote_img_container > img {
    width: 25px;
  }
  .profile_img_testimonial_container > img {
    width: 80px;
  }
  .name_of_user_text {
    font-size: 17px;
    line-height: 21px;
  }
  .tagline_of_user_content {
    font-size: 13px;
    line-height: 15px;
  }
  .extra_content_of_text {
    font-size: 13px;
    line-height: 16px;
    margin: 10px 0 0;
  }
  .name_of_user_text {
    font-size: 17px;
    line-height: 21px;
  }
  .logo_container {
    padding: 20px 0 0;
    display: flex;
    justify-content: center;
  }
  .logo_dwec_container {
    width: 60px;
  }
  .logo_container {
    padding: 20px 0 15px;
    display: flex;
    justify-content: space-evenly;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .img_bg_heading_section {
    width: 100%;
    height: 380px;
  }
  .testimonial_text_heading {
    font-size: 30px;
    line-height: 32px;
    text-align: center;
  }

  .left_testimonial_section {
    width: 0;
    padding: 35px;
    display: none;
  }
  .two_container_of_testimonial_section {
    flex-direction: column;
    width: 380px;

    position: relative;
    top: 0;

    margin: -15rem auto 0;
  }

  .right_testimonial_section {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 15px 30px;
  }
  .right_testimonial_under_content_container {
    padding: 35px 0 0;
  }
  .first_quote_img_container > img {
    width: 25px;
  }
  .profile_img_testimonial_container > img {
    width: 75%;
  }
  .name_of_user_text {
    font-size: 17px;
    line-height: 21px;
  }
  .tagline_of_user_content {
    font-size: 13px;
    line-height: 15px;
  }
  .extra_content_of_text {
    font-size: 13px;
    line-height: 16px;
    margin: 10px 0 0;
  }
  .name_of_user_text {
    font-size: 17px;
    line-height: 21px;
  }
  .logo_container {
    padding: 20px 0 0;
    display: flex;
    justify-content: center;
  }
  .logo_dwec_container {
    width: 75px;
  }
  .logo_container {
    padding: 28px 0 25px;
    display: flex;
    justify-content: space-evenly;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .img_bg_heading_section {
    height: 430px;
  }
  .logo_container {
    padding: 46px 0 40px;
    display: flex;
    justify-content: space-evenly;
  }
  .logo_dwec_container {
    width: 100px;
  }
  .two_container_of_testimonial_section {
    width: 700px;
  }
  .first_quote_img_container > img {
    transform: rotate(180deg);
    width: 23px;
  }
  .name_of_user_text {
    font-size: 19px;
    line-height: 21px;
  }
  .tagline_of_user_content {
    font-size: 14px;
    line-height: 19px;
  }
  .extra_content_of_text {
    font-family: poppinsLight;
    font-size: 11px;
    line-height: 15px;
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  .img_bg_heading_section {
    height: 430px;
  }
  .logo_container {
    padding: 46px 0 40px;
    display: flex;
    justify-content: space-evenly;
  }
  .logo_dwec_container {
    width: 100px;
  }
  .two_container_of_testimonial_section {
    width: 930px;
  }
  .first_quote_img_container > img {
    transform: rotate(180deg);
    width: 23px;
  }
  .name_of_user_text {
    font-size: 19px;
    line-height: 21px;
  }
  .tagline_of_user_content {
    font-size: 14px;
    line-height: 19px;
  }
  .extra_content_of_text {
    font-size: 13px;
    line-height: 20px;
  }
}

/* @media (max-width: 767px) {
  .img_bg_heading_section {
    width: 100%;

    height: 44vh;
  }
  .testimonial_text_heading {
    font-size: 30px;
    line-height: 32px;
    text-align: center;
  }

  .left_testimonial_section {
    width: 0;
    padding: 35px;
    display: none;
  }
  .two_container_of_testimonial_section {
    flex-direction: column;
    width: 100%;

    position: relative;
    top: 0;

    margin: -15rem auto 0;
  }

  .right_testimonial_section {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .right_testimonial_under_content_container {
    padding: 35px 0;
  }
  .first_quote_img_container > img {
    width: 25px;
  }
  .profile_img_testimonial_container > img {
    width: 75%;
  }
  .name_of_user_text {
    font-size: 17px;
    line-height: 21px;
  }
  .tagline_of_user_content {
    font-size: 13px;
    line-height: 15px;
  }
  .extra_content_of_text {
    font-size: 13px;
    line-height: 16px;
    margin: 10px 0 0;
  }
  .name_of_user_text {
    font-size: 17px;
    line-height: 21px;
  }
  .logo_container {
    padding: 20px 0 0;
    display: flex;
    justify-content: center;
  }
  .logo_dwec_container {
    width: 60px;
  }
  .logo_container {
    padding: 20px 0 15px;
    display: flex;
    justify-content: space-evenly;
  }
} */

@media (min-width: 1200px) {
  .img_bg_heading_section {
    height: 450px;
  }
  .logo_container {
    padding: 46px 0 40px;
    display: flex;
    justify-content: space-evenly;
  }
  .logo_dwec_container {
    width: 120px;
  }
  .two_container_of_testimonial_section {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 1100px;
    /* margin: 0 auto; */
    box-shadow: 5px 9px 6px -4px rgb(0 0 0 / 25%);
  }
}

@media (min-width: 1400px) {
  .logo_container {
    padding: 46px 0 40px;
    display: flex;
    justify-content: space-evenly;
  }
  .logo_dwec_container {
    width: 140px;
  }
  .img_bg_heading_section {
    height: 450px;
  }
  .two_container_of_testimonial_section {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 1300px;
    /* margin: 0 auto; */
    box-shadow: 5px 9px 6px -4px rgb(0 0 0 / 25%);
  }
}
