.widt_res_container {
  margin: 50px auto 50px;
}

@media (max-width: 767px) {
  .widt_res_container {
    width: 94%;
  }
}

@media (min-width: 767px) {
  .widt_res_container {
    width: 700px;
  }
}

@media (min-width: 991px) {
  .widt_res_container {
    width: 930px;
  }
}

@media (min-width: 1200px) {
  .widt_res_container {
    width: 1100px;
  }
}

@media (min-width: 1400px) {
  .widt_res_container {
    width: 1300px;
  }
}
