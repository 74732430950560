.main_language_slider_container {
  background-image: url("../../assets/lang_bg.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #e5e5e5;
  width: 100%;
}
.lang_body_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 70px 0 0;
}
.language_box_container {
  margin: 20px 0;
  display: flex;
  height: 200px;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  box-shadow: 2px 5px 7px rgb(0 0 0 / 25%);
  width: 80%;
  /* background: red; */
  /* background-color: var(--theme-white); */
  /* transition: transform 0.8s;
  transform-style: preserve-3d;
  perspective: 1000px; */
}
.language_box_container:hover {
  transform: rotateY(180deg);
}
.flip-card-back {
  background-color: #333;
  border: solid 1px #555;
  color: var(--theme-white);
  overflow: auto;
  padding: 20px;
  transform: rotateY(180deg);
}
.language_img_container {
  width: 80px;
}
.language_img_container > img {
  width: 100%;
}

.language_text {
  font-family: OswaldMed;
  color: var(--text-light-black);

  font-size: 23px;
  line-height: 29px;
}
.lang_btn_container {
  display: flex;
  width: 96.5%;
  margin: 20px 0;
  justify-content: flex-end;
}
.same_btn_styling {
  border: 0;
  width: 40px;
  height: 40px;
  font-size: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.left_icon_lang_btn {
  background: var(--section-black);
  margin: 0 10px 0 0;
  color: var(--theme-white);
}
.right_icon_lang_btn {
  background: var(--theme-green);

  color: var(--theme-white);
}
/* card  */
.flip-card {
  /* display: flex; */
  height: 200px;
  /* justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  box-shadow: 2px 5px 7px rgb(0 0 0 / 25%); */
  width: 80%;
  margin: 20px 0;
  /* transition: transform 0.8s;
  transform-style: preserve-3d;
  perspective: 1000px; */
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;

  transition: transform 0.9s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-back,
.flip-card-front {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.flip-card-front {
  color: black;
  background-color: var(--theme-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.flip-card-back {
  color: var(--theme-white);
  background-color: var(--theme-green);
  transform: rotateY(180deg);
}
.extra_text_paragraph {
  width: 100%;
  font-size: 13px;
  text-align: left;
  line-height: 19px;
  font-family: poppinsLight;
  height: 130px;
  overflow-x: hidden;
  overflow-y: auto;
}

.read_more_text {
  font-family: PoppinsMed;
  float: right;
}
