.social_link_main_section_container {
  background-color: #e5e5e5;
}
.social_link_under_main_container {
  width: 100%;
  margin: 0 auto;
}
.social_link_all_content_container {
  padding: 30px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.social_link_img_container {
  width: 100px;
  cursor: pointer;
}
.social_link_img_container > img {
  width: 40px;
}

@media (max-width: 299px) {
  .social_link_under_main_container {
    width: 100%;
    margin: 0 auto;
  }
  .social_link_all_content_container {
    padding: 25px 0;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .social_link_img_container {
    width: 30px;
  }
  .social_link_img_container > img {
    width: 22px;
  }
}

@media (min-width: 299px) and (max-width: 399px) {
  .social_link_under_main_container {
    width: 280px;
    margin: 0 auto;
  }
  .social_link_all_content_container {
    padding: 25px 0px;
    gap: 21px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .social_link_img_container {
    width: 30px;
  }
}

@media (min-width: 399px) and (max-width: 767px) {
  .social_link_under_main_container {
    width: 380px;
    margin: 0 auto;
  }
  .social_link_all_content_container {
    padding: 25px 0;
    gap: 46px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .social_link_img_container {
    width: 30px;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .social_link_under_main_container {
    width: 700px;
    margin: 0 auto;
  }
  .social_link_all_content_container {
    padding: 30px 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .social_link_img_container {
    width: 45px;
  }
  .social_link_img_container > img {
    width: 32px;
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  .social_link_under_main_container {
    width: 900px;
    margin: 0 auto;
  }
  .social_link_all_content_container {
    padding: 35px 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .social_link_img_container {
    width: 45px;
  }
}

@media (min-width: 1200px) {
  .social_link_under_main_container {
    width: 1100px;
    margin: 0 auto;
  }
  .social_link_all_content_container {
    padding: 30px 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .social_link_img_container {
    width: 55px;
  }
}

@media (min-width: 1400px) {
  .social_link_under_main_container {
    width: 1300px;
    margin: 0 auto;
  }
  .social_link_all_content_container {
    padding: 35px 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .social_link_img_container {
    width: 40px;
  }
}
