.proj_container_ {
  background-color: var(--theme-white);
  padding: 40px 0;
}
.main_gradient_container {
  margin: 0 auto;
  background-color: var(--theme-white);
  /* background: -moz-linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(27, 94, 32, 1) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(27, 94, 32, 1) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(27, 94, 32, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#1b5e20",GradientType=1); */
}
.main_view_project_container {
  text-align: center;

  background: #ffffff;
  box-shadow: 2px 4px 10px 1px rgb(0 0 0 / 25%);
  padding: 60px 35px;
}
.square_container {
  display: flex;
  flex-direction: column;
  width: 31.33%;
  text-align: left;
  background: #f0f0f0;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  padding: 20px 25px 6px;
}

.hash_text {
  font-family: OswaldSemiBold;
  text-align: end;
  color: var(--theme-green);
  font-size: 55px;
  line-height: 65px;
}

.proj_img_container {
  padding: 13px 0 0;
}
.proj_name_text {
  font-family: PoppinsMed;
  font-size: 24px;
  line-height: 33px;
}
.space_bet_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.proj_extra_text {
  font-family: PoppinsRegular;
  color: rgb(126 126 126 / 92%);
  font-size: 15px;
  line-height: 21px;
  margin: 5px 0 0;
}
.view_project_btn {
  text-align: center;
  font-family: PoppinsRegular;
  background: var(--theme-white);
  border: 0;
  padding: 15px;
  font-size: 20px;
  letter-spacing: 4px;
  color: var(--theme-green);
  margin: 20px 0;
}
.main_title_text_container {
  margin: 0 0 50px;
}
.flex_container_proj {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 299px) {
  .hash_text {
    font-size: 30px;
    line-height: 40px;
  }

  .main_view_project_container {
    padding: 30px 20px;
  }
  .main_gradient_container {
    width: 94%;
  }
  .flex_container_proj {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .square_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15px 15px 6px;
    margin: 0px 0 25px;
  }
  .proj_name_text {
    font-size: 16px;
    line-height: 23px;
  }
  .proj_extra_text {
    font-size: 12px;
    line-height: 17px;
  }
  .view_project_btn {
    padding: 10px 10px;
    font-size: 15px;
    letter-spacing: 2px;
    margin: 13px 0;
  }
}

@media (min-width: 300px) and (max-width: 399px) {
  .hash_text {
    font-size: 30px;
    line-height: 40px;
  }

  .main_view_project_container {
    padding: 30px 20px;
  }

  .flex_container_proj {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .square_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15px 15px 6px;
    margin: 0px 0 25px;
  }
  .proj_name_text {
    font-size: 16px;
    line-height: 23px;
  }
  .proj_extra_text {
    font-size: 12px;
    line-height: 17px;
  }
  .view_project_btn {
    padding: 10px 10px;
    font-size: 15px;
    letter-spacing: 2px;
    margin: 13px 0;
  }
  .main_view_project_container {
    padding: 30px 20px;
  }
  .main_gradient_container {
    width: 280px;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .hash_text {
    font-size: 30px;
    line-height: 40px;
  }

  .main_view_project_container {
    padding: 30px 20px;
  }

  .flex_container_proj {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .square_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15px 15px 6px;
    margin: 0px 0 25px;
  }
  .proj_name_text {
    font-size: 16px;
    line-height: 23px;
  }
  .proj_extra_text {
    font-size: 12px;
    line-height: 17px;
  }
  .view_project_btn {
    padding: 10px 10px;
    font-size: 15px;
    letter-spacing: 2px;
    margin: 13px 0;
  }
  .main_view_project_container {
    padding: 30px 20px;
  }
  .main_gradient_container {
    width: 380px;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .main_gradient_container {
    width: 700px;
  }
  .main_view_project_container {
    text-align: center;

    padding: 30px 26px;
  }
  .main_title_text_container {
    margin: 0 0 40px;
  }
  .square_container {
    width: 31.33%;

    padding: 13px 15px 6px;
  }
  .hash_text {
    font-size: 40px;
    line-height: 45px;
  }
  .proj_name_text {
    font-size: 18px;
    line-height: 24px;
  }
  .proj_extra_text {
    font-size: 12px;
    line-height: 16px;
    margin: 6px 0 0;
  }
  .view_project_btn {
    padding: 12px;
    font-size: 15px;
    letter-spacing: 2px;

    margin: 15px 0 11px;
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  .main_view_project_container {
    text-align: center;

    padding: 30px 26px;
  }
  .main_title_text_container {
    margin: 0 0 40px;
  }
  .square_container {
    width: 31.33%;

    padding: 13px 15px 6px;
  }
  .hash_text {
    font-size: 40px;
    line-height: 45px;
  }
  .proj_name_text {
    font-size: 18px;
    line-height: 24px;
  }
  .proj_extra_text {
    font-size: 12px;
    line-height: 16px;
    margin: 6px 0 0;
  }
  .view_project_btn {
    padding: 12px;
    font-size: 15px;
    letter-spacing: 2px;

    margin: 15px 0 11px;
  }
  .main_gradient_container {
    width: 930px;
  }
}

@media (min-width: 1200px) {
  .main_gradient_container {
    width: 1100px;
  }
}

@media (min-width: 1400px) {
  .main_gradient_container {
    width: 1300px;
  }
}
