.about_the_company_main_section {
  padding: 60px 0;
  background: var(--theme-white);
}
.company_under_the_main_section {
  margin: 0 auto;
}
.two_part_of_the_section {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.first_part_of_section {
  width: 45%;
}
.first_part_under_section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}
.work_flow_text_heading_about {
  font-family: OswaldSemiBold;
  font-size: 60px;
  line-height: 70px;
  text-transform: uppercase;
  color: var(--section-black);
}
.highlighted_title_text_about {
  font-family: OswaldSemiBold;
  font-size: 60px;
  line-height: 70px;
  text-transform: uppercase;
  color: var(--theme-green);
}
.tagline_of_company_text {
  font-family: PoppinsMed;
  font-size: 22px;
  line-height: 30px;
  margin: 10px 0 0;
}
.description_of_about_paragraph {
  font-family: PoppinsRegular;
  line-height: 27px;
  font-size: 17px;
  margin: 12px 0 18px;
}

.read_more_btn_about_company {
  font-family: "PoppinsRegular";
  width: 38%;
  font-size: 21px;
  background: var(--theme-green);
  color: #ffffffe0;
  border: 0;
  height: 57px;
  transition: var(--transition);
  letter-spacing: 3px;
}
.read_more_btn_about_company:hover {
  background: var(--section-black);
  color: #ffffff;
}
.sec_part_of_section {
  width: 40%;
}
.sec_part_under_section {
  display: flex;
  flex-direction: column;
  background: var(--theme-green);
  color: var(--theme-white);
  padding: 40px;
}

.our_vision_text {
  font-family: OswaldSemiBold;
  font-size: 60px;
  line-height: 70px;
  text-transform: uppercase;
  color: var(--theme-white);
  margin: 0 0 15px 0;
}
.all_vision_content_container > li {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 10px 0;
  font-family: "PoppinsMed";
  font-size: 17px;
}
/* .sec_part_under_section > li::before {
  content: "";
  display: inline-block;
  height: 25px;
  width: 25px;
  background-size: 25px;
  background-image: url("../../assets/true_mark.svg");
  background-repeat: no-repeat;
  margin-right: 12px;
} */
.all_vision_content_container > li::before {
  content: "";
  display: inline-block;
  height: 25px;
  width: 25px;
  background-size: 25px;
  background-image: url("../../assets/true_mark.svg");
  background-repeat: no-repeat;
  margin-right: 12px;
}

.about_us_img_container {
  position: relative;
  right: 47%;
  top: 11px;
}
.about_ds_img_container {
}
.ds_img {
  width: 100%;
}
@media (max-width: 299px) {
  .company_under_the_main_section {
    width: 100%;
  }
  .about_the_company_main_section {
    padding: 35px 0;
  }

  .two_part_of_the_section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
  }
  .work_flow_text_heading_about,
  .highlighted_title_text_about,
  .our_vision_text {
    font-size: 30px;
    line-height: 32px;
    text-align: center;
  }
  .tagline_of_company_text {
    font-size: 16px;
    line-height: 21px;
    margin: 8px 0 0;
    text-align: center;
  }
  .first_part_of_section {
    width: 94%;
    margin: 0 auto;
  }

  .sec_part_of_section {
    width: 100%;
  }
  .first_part_under_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
  }
  .description_of_about_paragraph {
    line-height: 18px;
    font-size: 12px;
    margin: 7px 0 9px;
    text-align: justify;
  }
  .read_more_btn_about_company {
    width: 47%;
    font-size: 15px;

    height: 43px;
    letter-spacing: 2px;
  }

  .sec_part_under_section {
    padding: 25px 18px 18px;
    margin: 30px 0 0;
    display: flex;
    flex-direction: column;
    background: var(--theme-green);
    color: var(--theme-white);
  }
  .all_vision_content_container > li {
    margin: 10px 0;
    font-size: 9px;
    font-family: "PoppinsRegular";
    letter-spacing: 0.3px;
  }
  .all_vision_content_container > li::before {
    height: 15px;
    width: 15px;
    background-size: 15px;

    margin-right: 9px;
  }
  .about_us_img_container {
    position: relative;
    right: 0;
    top: 8px;
    margin: 20px 0;
  }
  .about_ds_img_container {
    background: var(--theme-white);
    padding: 8% 0;
    border-radius: 15px;
  }
}

@media (min-width: 300px) and (max-width: 399px) {
  .about_ds_img_container {
    background: var(--theme-white);
    padding: 8% 0 5%;
    border-radius: 8px;
    width: 90%;
    margin: 10px auto 18px;
  }
  .company_under_the_main_section {
    /* width: 280px; */
  }
  .about_the_company_main_section {
    padding: 35px 0;
  }

  .two_part_of_the_section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
  }
  .work_flow_text_heading_about,
  .highlighted_title_text_about,
  .our_vision_text {
    font-size: 30px;
    line-height: 32px;
    text-align: center;
  }
  .tagline_of_company_text {
    font-size: 16px;
    line-height: 21px;
    margin: 8px 0 0;
    text-align: center;
  }
  .first_part_of_section {
    width: 280px;
  }
  .sec_part_of_section {
    width: 100%;
  }
  .first_part_under_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
  }
  .description_of_about_paragraph {
    line-height: 18px;
    font-size: 12px;
    margin: 7px 0 9px;
    text-align: justify;
  }
  .read_more_btn_about_company {
    width: 47%;
    font-size: 15px;

    height: 43px;
    letter-spacing: 2px;
  }
  .sec_part_under_section {
    padding: 25px 18px 18px;
    margin: 30px 0 0;
  }
  .all_vision_content_container > li {
    margin: 5px 0;
    font-size: 11px;
  }
  .all_vision_content_container > li::before {
    height: 15px;
    width: 14px;
    background-size: 13px;
    margin-right: 11px;
  }
  .about_us_img_container {
    position: relative;
    right: 0;
    top: 8px;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .about_ds_img_container {
    background: var(--theme-white);
    padding: 9% 0 6%;
    border-radius: 7px;
    width: 380px;
    margin: 20px auto;
  }
  .company_under_the_main_section {
    /* width: 380px; */
  }
  .about_the_company_main_section {
    padding: 35px 0;
  }
  .all_vision_content_container {
    width: 380px;
    margin: 0 auto;
  }
  .two_part_of_the_section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
  }
  .work_flow_text_heading_about,
  .highlighted_title_text_about,
  .our_vision_text {
    font-size: 30px;
    line-height: 32px;
    text-align: center;
  }
  .tagline_of_company_text {
    font-size: 16px;
    line-height: 21px;
    margin: 8px 0 0;
    text-align: center;
  }
  .first_part_of_section {
    width: 380px;
  }
  .sec_part_of_section {
    width: 100%;
  }
  .first_part_under_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
  }
  .description_of_about_paragraph {
    line-height: 18px;
    font-size: 12px;
    margin: 7px 0 9px;
    text-align: justify;
  }
  .read_more_btn_about_company {
    width: 47%;
    font-size: 15px;

    height: 43px;
    letter-spacing: 2px;
  }
  .sec_part_under_section {
    padding: 25px 18px 18px;
    margin: 30px 0 0;
  }
  .all_vision_content_container > li {
    margin: 5px 0;

    font-size: 13px;
  }
  .all_vision_content_container > li::before {
    height: 18px;
    width: 18px;
    background-size: 18px;

    margin-right: 9px;
  }
  .about_us_img_container {
    position: relative;
    right: 0;
    top: 8px;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .about_ds_img_container {
    background: var(--theme-white);
    box-shadow: 13px 0px 22px 13px rgb(27 94 32 / 17%);
    padding: 11% 0 12%;
    margin: 0 0 0px;
    border-radius: 3px;
  }
  .about_the_company_main_section {
    padding: 35px 0;
  }
  .company_under_the_main_section {
    width: 750px;
  }
  .work_flow_text_heading_about,
  .highlighted_title_text_about,
  .our_vision_text {
    font-size: 40px;
    line-height: 42px;
  }
  .tagline_of_company_text {
    font-size: 17px;
    line-height: 22px;
    margin: 11px 0 0;
  }
  .description_of_about_paragraph {
    line-height: 19px;
    font-size: 13px;
    margin: 4px 0 9px;
  }
  .read_more_btn_about_company {
    width: 47%;
    font-size: 15px;

    height: 45px;
    letter-spacing: 3px;
  }
  .sec_part_under_section {
    padding: 20px;
  }
  .all_vision_content_container > li {
    margin: 7px 0;
    font-size: 10px;
  }
  .all_vision_content_container > li::before {
    height: 18px;
    width: 18px;
    background-size: 18px;
    margin-right: 8px;
  }
  .about_us_img_container {
    position: relative;
    right: 41%;
    top: 23px;
    margin: 24px 0 38px;
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  .about_ds_img_container {
    background: var(--theme-white);
    box-shadow: 13px 0px 22px 13px rgb(27 94 32 / 17%);
    padding: 16% 0 12%;
    margin: 0 0 0px;
    border-radius: 3px;
  }
  .about_the_company_main_section {
    padding: 45px 0;
  }
  .company_under_the_main_section {
    width: 930px;
  }
  .work_flow_text_heading_about,
  .highlighted_title_text_about,
  .our_vision_text {
    font-size: 43px;
    line-height: 51px;
  }
  .tagline_of_company_text {
    font-size: 18px;
    line-height: 26px;
    margin: 12px 0 0;
  }
  .description_of_about_paragraph {
    line-height: 22px;
    font-size: 15px;
    margin: 6px 0 12px;
  }
  .read_more_btn_about_company {
    width: 41%;
    font-size: 15px;
    height: 45px;
    letter-spacing: 3px;
  }
  .sec_part_under_section {
    padding: 28px;
  }
  .all_vision_content_container > li {
    margin: 6px 0;
    font-size: 12px;
  }
  .all_vision_content_container > li::before {
    height: 20px;
    width: 20px;
    background-size: 20px;
    margin-right: 9px;
  }
  .about_us_img_container {
    position: relative;
    right: 45%;
    top: 27px;
    margin: 34px 0 47px;
  }
}

@media (min-width: 1200px) {
  .all_vision_content_container > li {
    margin: 10px 0;

    font-size: 14px;
    line-height: 17px;
  }
  .all_vision_content_container > li::before {
    height: 20px;
    width: 20px;
    margin-right: 9px;
    background-size: 20px;
  }
  .about_ds_img_container {
    background: var(--theme-white);
    box-shadow: 13px 0px 22px 13px rgb(27 94 32 / 17%);
    padding: 0% 0 0%;
    margin: 0 0 0px;
    border-radius: 3px;
  }
  .about_us_img_container {
    position: relative;
    right: 42%;
    top: 10px;
    margin: 56px 0 43px;
  }
  .company_under_the_main_section {
    width: 1100px;
  }
}

@media (min-width: 1400px) {
  .all_vision_content_container > li {
    margin: 10px 0;
    font-size: 15px;
    line-height: 17px;
  }
  .all_vision_content_container > li::before {
    height: 22px;
    width: 22px;
    margin-right: 12px;
    background-size: 22px;
  }
  .company_under_the_main_section {
    width: 1300px;
  }
  .description_of_about_paragraph {
    line-height: 32px;
    font-size: 19px;
    margin: 14px 0 21px;
  }
  .tagline_of_company_text {
    font-size: 23px;
    line-height: 31px;
    margin: 11px 0 0;
  }
}
