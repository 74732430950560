.main_video_slider_container {
  padding: 60px 0;
  background-color: var(--theme-white);
  width: 100%;
}
.under_main_img_slider_container {
}
.proj_img_slider_container {
}
.proj_img_container {
}
.proj_img_one {
}
.main_title_content_container {
  text-align: center;
  margin: 20px 0;
}
.under_main_img_slider_container {
}
button.rec-dot {
  background-color: #99ba9cbf;

  box-shadow: 0 0 1px 3px #1b5e20;
}

button.rec-dot:active,
button.rec-dot:focus {
  background-color: var(--theme-green);
  cursor: pointer;
  box-shadow: 0 0 1px 3px #99ba9cbf;
}

@media (max-width: 399px) {
  .main_video_slider_container {
    padding: 20px 0;
  }
  .proj_img_slider_container {
    width: 90%;
    margin: 0 auto;
  }
  .same_btn_styling {
    height: 30px;
    width: 30px;
    font-size: 20px;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .main_video_slider_container {
    padding: 20px 0;
  }
  .proj_img_slider_container {
    width: 90%;
    margin: 0 auto;
  }
  .same_btn_styling {
    height: 30px;
    width: 30px;
    font-size: 20px;
  }
  .under_main_img_slider_container {
    width: 380px;
    margin: 0 auto;
  }
}

@media (min-width: 768px) {
  .under_main_img_slider_container {
    width: 700px;
    margin: 0 auto;
  }
  .same_btn_styling {
    height: 30px;
    width: 30px;
    font-size: 20px;
  }
  .left_icon_lang_btn {
    margin: 0 6px 0 0;
  }
}

@media (min-width: 991px) {
  .under_main_img_slider_container {
    width: 930px;
    margin: 0 auto;
  }
  .same_btn_styling {
    height: 30px;
    width: 30px;
    font-size: 20px;
  }
  .left_icon_lang_btn {
    margin: 0 6px 0 0;
  }
}

@media (min-width: 1200px) {
  .under_main_img_slider_container {
    width: 1100px;
    margin: 0 auto;
  }
  .proj_img_slider_container {
    width: 80%;
    margin: 0 auto;
  }

  .lang_btn_container {
    display: flex;
    width: 98.5%;
    margin: 0px 0;
  }
}

@media (min-width: 1400px) {
  .under_main_img_slider_container {
    width: 1300px;
    margin: 0 auto;
  }
}
