.proj_main_container {
  padding: 66px 0 100px 0px;
  background: var(--theme-white);
}
.section_under_main_container {
}
.green_container_project_section {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 13%,
    rgba(27, 94, 32, 1) 12%
  );
  color: var(--theme-white);
}
.green_two_section_container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 66px 0 0;
  position: relative;
  top: 29px;
}
.first_green_section_container {
  width: 50%;
}
.four_all_green_container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px 10px;
}
.img_for_proj_container {
  width: 48%;
}
.sec_green_section_container {
  width: 47%;
}

.title_project_text {
  font-family: OswaldSemiBold;
  font-size: 60px;
  line-height: 70px;
  text-transform: uppercase;
  color: var(--theme-white);
  margin: 0 0 15px 0;
}
.extra_content_of_project {
  font-family: poppinsRegular;
  margin: 0px 0 10px;
  font-size: 17px;
  line-height: 22px;
  width: 82%;
}
.extra_content1_of_project {
  font-family: poppinsRegular;
  margin: 0px 0 20px;
  font-size: 17px;
  width: 82%;
  line-height: 22px;
}
.view_more_btn_by_project {
  font-family: "PoppinsRegular";
  width: 38%;
  font-size: 21px;
  background: var(--theme-white);
  color: var(--theme-green);
  border: 0;
  height: 57px;
  letter-spacing: 3px;
  transition: var(--transition);
}
.view_more_btn_by_project:hover {
  background: var(--section-black);
  color: #ffffff;
}
.img_for_proj_container > img {
  filter: brightness(0.8);
  border-radius: 3px;
  transition: 0.5s;
}
.img_for_proj_container > img:hover {
  filter: brightness(1);
  transform: scale(1.1);
}

@media (max-width: 767px) {
  .img_for_proj_container {
    height: 100%;
  }
}

@media (max-width: 299px) {
  .proj_main_container {
    padding: 40px 0 60px 0px;
  }
  .view_more_btn_by_project {
    width: 44%;
    font-size: 14px;

    border: 0;
    height: 42px;
    letter-spacing: 3px;
    margin: 0 0 29px 0;
  }
  .title_project_text {
    font-size: 30px;
    line-height: 34px;

    margin: 0 0 15px 0;
  }
  .green_container_project_section {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(27, 94, 32, 1) 0%
    );
    width: 100%;
  }
  .first_green_section_container {
    width: 100%;
  }
  .sec_green_section_container {
    width: 100%;
  }
  .green_two_section_container {
    justify-content: space-between;
    flex-direction: column-reverse;
    padding: 0px 0 0;
    position: relative;
    top: 29px;
    align-content: center;
    align-items: center;
    width: 94%;
    margin: 0 auto;
  }
  .green_two_section_container {
    flex-direction: column-reverse;
    padding: 0px 0 0;

    top: 29px;
    align-content: center;
    align-items: center;
  }
  .extra_content_of_project {
    margin: 0px 0 10px;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
  }
  .extra_content1_of_project {
    margin: 0px 0 11px;
    font-size: 14px;
    width: 100%;
    line-height: 19px;
  }
}

@media (min-width: 300px) and (max-width: 399px) {
  .proj_main_container {
    padding: 40px 0 60px 0px;
  }
  .four_all_green_container {
    gap: 5px 10px;
  }
  .view_more_btn_by_project {
    width: 50%;
    font-size: 13px;
    border: 0;
    height: 42px;
    letter-spacing: 3px;
    margin: 0 0 29px 0;
  }
  .title_project_text {
    font-size: 30px;
    line-height: 34px;

    margin: 0 0 15px 0;
  }
  .green_container_project_section {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(27, 94, 32, 1) 0%
    );
    width: 100%;
  }
  .first_green_section_container {
    width: 100%;
  }
  .sec_green_section_container {
    width: 100%;
  }
  .green_two_section_container {
    justify-content: space-between;
    flex-direction: column-reverse;
    padding: 0px 0 0;
    position: relative;
    top: 29px;
    align-content: center;
    align-items: center;
    width: 94%;
    margin: 0 auto;
  }
  .green_two_section_container {
    flex-direction: column-reverse;
    padding: 0px 0 0;
    top: 29px;
    align-content: center;
    align-items: center;
    width: 280px;
    margin: 0 auto;
  }
  .extra_content_of_project {
    margin: 0px 0 10px;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
  }
  .extra_content1_of_project {
    margin: 0px 0 11px;
    font-size: 14px;
    width: 100%;
    line-height: 19px;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .proj_main_container {
    padding: 40px 0 60px 0px;
  }
  .four_all_green_container {
    gap: 5px 10px;
  }
  .view_more_btn_by_project {
    width: 38%;
    font-size: 13px;
    border: 0;
    height: 40px;
    letter-spacing: 3px;
    margin: 0 0 25px 0;
  }
  .title_project_text {
    font-size: 30px;
    line-height: 34px;

    margin: 0 0 15px 0;
  }
  .green_container_project_section {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(27, 94, 32, 1) 0%
    );
    width: 100%;
  }
  .first_green_section_container {
    width: 100%;
  }
  .sec_green_section_container {
    width: 100%;
  }
  .green_two_section_container {
    justify-content: space-between;
    flex-direction: column-reverse;
    padding: 0px 0 0;
    position: relative;
    top: 29px;
    align-content: center;
    align-items: center;
    width: 94%;
    margin: 0 auto;
  }
  .green_two_section_container {
    flex-direction: column-reverse;
    padding: 0px 0 0;
    top: 29px;
    align-content: center;
    align-items: center;
    width: 380px;
    margin: 0 auto;
  }
  .extra_content_of_project {
    margin: 0px 0 10px;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
  }
  .extra_content1_of_project {
    margin: 0px 0 11px;
    font-size: 14px;
    width: 100%;
    line-height: 19px;
  }
}

@media (min-width: 767px) and (max-width: 990px) {
  .four_all_green_container {
    padding: 78px 0 0;
  }
  .proj_main_container {
    padding: 40px 0 80px 0px;
  }
  .img_for_proj_container > img {
    height: 100%;
  }
  .title_project_text {
    font-size: 40px;
    line-height: 42px;

    margin: 0 0 15px 0;
  }
  .green_two_section_container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 14px 0 10px;
    position: relative;
    top: 30px;
    width: 700px;
    margin: 0 auto;
  }
  .extra_content_of_project {
    margin: 0px 0 13px;
    font-size: 15px;
    line-height: 21px;
    width: 95%;
  }
  .extra_content1_of_project {
    margin: 0px 0 10px;
    font-size: 15px;
    line-height: 23px;
    width: 95%;
  }
  .view_more_btn_by_project {
    width: 37%;
    font-size: 13px;

    border: 0;
    height: 37px;
    letter-spacing: 3px;
  }
  .sec_green_section_container {
    width: 47%;
    margin: 15px 0 0 0;
  }
}
@media (min-width: 991px) and (max-width: 1199px) {
  .four_all_green_container {
    padding: 86px 0 0;
  }
  .img_for_proj_container > img {
    height: 100%;
  }
  .title_project_text {
    font-size: 43px;
    line-height: 51px;
    margin: 0 0 15px 0;
  }
  .green_two_section_container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 14px 0 10px;
    position: relative;
    top: 57px;
    width: 930px;
    margin: 0 auto;
  }
  .extra_content_of_project {
    margin: 0px 0 13px;
    font-size: 17px;
    line-height: 24px;
    width: 100%;
  }
  .extra_content1_of_project {
    margin: 0px 0 13px;
    font-size: 17px;
    line-height: 24px;
    width: 100%;
  }
  .view_more_btn_by_project {
    width: 31%;
    font-size: 14px;

    height: 42px;
    letter-spacing: 3px;
  }
  .sec_green_section_container {
    width: 47%;
    margin: 15px 0 0 0;
  }
}

@media (min-width: 1200px) {
  .section_under_main_container {
    width: 1100px;
    margin: 0 auto;
  }
  .img_for_proj_container > img {
    height: 100%;
  }
  .title_project_text {
    font-size: 60px;
    line-height: 66px;
    margin: 0 0 15px 0;
  }
  .green_two_section_container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 14px 0 10px;
    position: relative;
    top: 57px;
    width: 1100px;
    margin: 0 auto;
  }
  .extra_content_of_project {
    margin: 0px 0 13px;
    font-size: 17px;
    line-height: 24px;
    width: 90%;
  }
  .extra_content1_of_project {
    margin: 0px 0 20px;
    font-size: 17px;
    line-height: 24px;
    width: 90%;
  }
  .view_more_btn_by_project {
    width: 36%;
    font-size: 20px;
    height: 51px;
    letter-spacing: 3px;
  }
  .sec_green_section_container {
    width: 47%;
    margin: 15px 0 0 0;
  }
  .four_all_green_container {
    padding: 100px 0 0;
  }
}

@media (min-width: 1400px) {
  .section_under_main_container {
    width: 1300px;
    margin: 0 auto;
  }
  .img_for_proj_container > img {
    height: 100%;
  }
  .title_project_text {
    font-size: 60px;
    line-height: 66px;
    margin: 0 0 15px 0;
  }
  .green_two_section_container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 14px 0 10px;
    position: relative;
    top: 57px;
    width: 1300px;
    margin: 0 auto;
  }
  .extra_content_of_project {
    margin: 0px 0 13px;
    font-size: 19px;
    line-height: 29px;
    width: 93%;
  }
  .extra_content1_of_project {
    margin: 0px 0 13px;
    font-size: 19px;
    line-height: 29px;
    width: 93%;
  }
  .view_more_btn_by_project {
    width: 34%;
    font-size: 20px;
    height: 56px;
    letter-spacing: 3px;
  }
  .sec_green_section_container {
    width: 47%;
    margin: 15px 0 0 0;
  }
  .four_all_green_container {
    gap: 16px 10px;
    padding: 104px 0 0;
  }
}
