.next_role_section_container {
}
.next_under_section_container {
  margin: 0 auto;
  padding: 60px auto;
}
.vertical_rect {
  display: flex;
  justify-content: center;
  width: 35%;
  background-color: var(--theme-green);
  position: absolute;
  top: 0;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0%);
}
.flex_container1 {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  background: transparent;
  /* height: 100vh; */
  width: 100%;
  position: relative;
  padding: 7% 0;
}
.next_img_container {
  width: 65%;
  /* border: 1px solid #000; */
  z-index: 1;
  margin: 0 auto;
}
.next_img_container > img {
  width: 100%;
}
.next_rect_container {
  padding: 2%;
  background: var(--theme-white);
  display: flex;
  width: 31%;
  margin: -103px auto 0 10%;
  gap: 25px;
  z-index: 1;
  flex-direction: column;
  color: var(--section-black);
  font-family: "OswaldSemiBold";
  border: 1px solid rgba(0, 0, 0, 0.11);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}
.next_rect_container > p {
  color: var(--theme-black);
  font-size: 32px;
  line-height: 34px;
}
.next_rect_container > button {
}

@media (max-width: 299px) {
  .vertical_rect {
    width: 26%;

    position: absolute;
    top: 0;
    left: 50%;
  }
  .next_rect_container > p {
    color: var(--theme-black);
    font-size: 16px;
    line-height: 21px;
  }
  .next_rect_container {
    padding: 4%;

    width: 66%;
    margin: -49px auto 0 4%;
    gap: 8px;
  }
}

@media (min-width: 299px) and (max-width: 399px) {
  .vertical_rect {
    width: 26%;

    position: absolute;
    top: 0;
    left: 50%;
  }
  .next_rect_container > p {
    color: var(--theme-black);
    font-size: 16px;
    line-height: 21px;
  }
  .next_rect_container {
    padding: 4%;
    width: 56%;
    margin: -49px auto 0 4%;
    gap: 8px;
  }
}

@media (min-width: 399px) and (max-width: 767px) {
  .vertical_rect {
    width: 26%;

    position: absolute;
    top: 0;
    left: 50%;
  }
  .next_rect_container > p {
    font-size: 21px;
    line-height: 28px;
  }
  .next_rect_container {
    padding: 3%;
    width: 61%;
    margin: -80px auto 0 5%;
    gap: 7px;
  }
}

@media (min-width: 767px) and (max-width: 990px) {
  .next_rect_container {
    width: 42%;
    margin: -103px auto 0 10%;
    gap: 18px;
  }
  .next_rect_container > p {
    font-size: 25px;
    line-height: 32px;
  }
}
@media (min-width: 990px) and (max-width: 1200px) {
  .next_rect_container {
    width: 35%;
    margin: -7rem auto 0 10%;
    gap: 25px;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .next_under_section_container {
    width: 1100px;
  }
}

@media (min-width: 1400px) {
  .next_under_section_container {
    width: 1300px;
  }
  .next_img_container {
    width: 70%;
  }
  .next_rect_container {
    padding: 2%;
    background: var(--theme-white);
    display: flex;
    width: 29%;
    margin: -114px auto 0 9%;
    gap: 25px;
    z-index: 1;
    flex-direction: column;
    color: var(--section-black);
    font-family: "OswaldSemiBold";
    border: 1px solid rgba(0, 0, 0, 0.11);
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  }
  /* .next_img_container {
    width: 1000px;
    margin: 23px 0 0;
  } */
}
