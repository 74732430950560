.footer_main_contaier {
  background: var(--theme-green);
}
.footer_under_main_contaier {
  margin: 0 auto;
  padding: 60px 0;
}

.footer_container {
}
.footer_all_two_part_contaienr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  /* height: 60vh; */
  width: 100%;
}
.footer_first_part_container {
  width: 35%;
  display: flex;
  flex-direction: column;
}
.footer_sec_part_container {
  width: 20%;
  display: flex;
  flex-direction: column;
}

.first_part_content_container {
}

.title_text_footer {
  font-family: OswaldMed;
  font-size: 24px;
  line-height: 30px;
  margin: 10px 0;
  color: var(--theme-white);
}
.subtitle_text_footer {
  font-family: PoppinsLight;
  font-size: 20px;
  line-height: 29px;
  color: var(--theme-white);
  margin: 0;
  cursor: pointer;
  transition: var(--transition);
}
.subtitle_text_footer:hover {
  color: var(--theme-black);
  font-family: PoppinsMed;
}
.footer_sec_part_container > button {
  font-size: 16px;
  height: 40px;
  font-family: "PoppinsSemiBold";
  color: var(--theme-green);
  width: 160px;
  border: 0;
  margin: 10px 0;
}
.align_text {
  text-align: right;
  color: var(--theme-white);
  margin: 20px 0;
}

@media (max-width: 299px) {
  .footer_under_main_contaier {
    padding: 30px 0;
    width: 94%;
    text-align: center;
  }
  .footer_all_two_part_contaienr {
    display: flex;
    flex-direction: column;

    align-items: flex-start;
  }
  .footer_first_part_container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .footer_sec_part_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .title_text_footer {
    font-size: 18px;
    line-height: 27px;
  }
  .subtitle_text_footer {
    font-size: 14px;
    line-height: 21px;
  }
  .align_text {
    text-align: center;
    color: var(--theme-white);
    margin: 43px 0;
    font-size: 19px;
  }
}

@media (min-width: 299px) and (max-width: 399px) {
  .footer_under_main_contaier {
    padding: 30px 0;
    width: 94%;
    text-align: center;
  }
  .footer_all_two_part_contaienr {
    display: flex;
    flex-direction: column;

    align-items: flex-start;
  }
  .footer_first_part_container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .footer_sec_part_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .title_text_footer {
    font-size: 18px;
    line-height: 27px;
    margin: 6px 0;
  }
  .subtitle_text_footer {
    font-size: 14px;
    line-height: 21px;
  }
  .align_text {
    text-align: center;
    color: var(--theme-white);
    margin: 43px 0;
    font-size: 19px;
  }
  .footer_sec_part_container > button {
    font-size: 13px;
    height: 35px;

    width: 120px;
    border: 0;
    margin: 5px 0;
  }
}

@media (min-width: 399px) and (max-width: 767px) {
  .footer_under_main_contaier {
    padding: 30px 0;
    width: 94%;
    text-align: center;
  }
  .footer_all_two_part_contaienr {
    display: flex;
    flex-direction: column;

    align-items: flex-start;
  }
  .footer_first_part_container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .footer_sec_part_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .title_text_footer {
    font-size: 18px;
    line-height: 27px;
    margin: 6px 0;
  }
  .subtitle_text_footer {
    font-size: 14px;
    line-height: 21px;
  }
  .align_text {
    text-align: center;
    color: var(--theme-white);
    margin: 43px 0;
    font-size: 19px;
  }
  .footer_sec_part_container > button {
    font-size: 13px;
    height: 35px;
    margin: 0 auto;
    width: 120px;
    border: 0;
    margin: 5px 0;
  }
}

@media (min-width: 767px) {
  .footer_under_main_contaier {
    width: 700px;
  }
  .title_text_footer {
    font-size: 18px;
    line-height: 22px;
  }
  .subtitle_text_footer {
    font-size: 14px;
    line-height: 20px;
  }
  .footer_sec_part_container > button {
    font-size: 13px;
    height: 35px;

    width: 100px;

    margin: 3px 0;
  }
}
@media (min-width: 990px) {
  .footer_under_main_contaier {
    width: 930px;
  }
  .title_text_footer {
    font-size: 19px;
    line-height: 22px;
  }
  .subtitle_text_footer {
    font-size: 15px;
    line-height: 20px;
  }
  .footer_sec_part_container > button {
    font-size: 14px;
    height: 38px;

    width: 120px;

    margin: 3px 0;
  }
}

@media (min-width: 1200px) {
  .footer_under_main_contaier {
    width: 1100px;
  }
  .title_text_footer {
    font-size: 24px;
    line-height: 30px;
  }
  .subtitle_text_footer {
    font-size: 17px;
    line-height: 29px;
  }
}

@media (min-width: 1400px) {
  .footer_under_main_contaier {
    width: 1300px;
  }
  .title_text_footer {
    font-size: 24px;
    line-height: 30px;
  }
  .subtitle_text_footer {
    font-size: 17px;
    line-height: 29px;
  }
}
