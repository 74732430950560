.bg_read_more_section {
  /* background: url("../../assets/development_bg.svg") no-repeat fixed center; */
  background-image: url("../../assets/read_more_section.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;

  margin: 0 auto;
  min-height: 100vh;
  position: relative;
}
.bg_read_more_hero_section_container {
}
.green_read_more_container_heading {
  padding: 25px 40px;
  /* background: var(--theme-green); */
  display: inline-flex;
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 62%,
    rgba(27, 94, 32, 1) 41%
  );
}
.main_content_read_more_container {
  background-image: url("../../assets/read_more_abstract_bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 30px 0;
  margin: 0 0 30px 0;
}
.under_main_read_more_container {
}
.content_service_container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}
.content_left_service_container {
  width: 50%;
  /* display: flex;
  flex-direction: row;
  justify-content: flex-end; */
}
.content_img_container {
  width: 500px;
}
.content_img_container > img {
  width: 100%;
}
.content_right_service_container {
  width: 50%;
  font-family: PoppinsRegular;
  font-size: 22px;
  letter-spacing: 0.5px;
  color: #000;
}
.content_right_service_container > p {
}
.center_service_text {
  font-family: PoppinsRegular;
  font-size: 22px;
  letter-spacing: 0.5px;
  color: #000;
  text-align: center;
  margin: 15px 0;
}
.end_of_content_container {
  font-family: PoppinsRegular;
  font-size: 22px;
  letter-spacing: 0.5px;
  color: #000;
  margin: 30px 0;
}
.our_work_read_more_title_text {
  font-family: OswaldSemiBold;
  color: var(--theme-white);
  font-size: 70px;
  line-height: 80px;
  text-align: left;
}
.development_pic_container_center {
  width: 100%;
  margin: 0 auto;
}

@media (max-width: 299px) {
  .content_right_service_container,
  .center_service_text,
  .end_of_content_container {
    font-size: 12px;
  }
  .bg_read_more_section {
    min-height: 80vh;
  }
  .green_read_more_container_heading {
    padding: 15px;
  }
  .content_service_container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .content_img_container {
    width: 100%;
  }
  .under_main_read_more_container {
    width: 94%;
    margin: 0 auto;
  }
  .content_left_service_container {
    width: 100%;
  }
  .content_right_service_container {
    width: 100%;

    /* font-size: 15px; */
    letter-spacing: 0.5px;

    line-height: 20px;
  }
  .center_service_text {
    letter-spacing: 0.5px;

    line-height: 20px;
  }
  .end_of_content_container {
    letter-spacing: 0.5px;
    line-height: 20px;
    margin: 0;
  }
  .our_work_read_more_title_text {
    font-size: 30px;
    line-height: 35px;
  }
}

@media (min-width: 300px) and (max-width: 399px) {
  .content_right_service_container,
  .center_service_text,
  .end_of_content_container {
    font-size: 12px;
  }
  .our_work_read_more_title_text {
    font-size: 30px;
    line-height: 35px;
  }
  .main_content_read_more_container {
    padding: 0px 0 20px;
  }
  .bg_read_more_section {
    min-height: 80vh;
  }
  .green_read_more_container_heading {
    padding: 15px;
  }
  .content_service_container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .content_img_container {
    width: 70%;
    margin: 0 auto;
  }
  .under_main_read_more_container {
    width: 280px;
    margin: 0 auto;
  }
  .content_left_service_container {
    width: 100%;
  }
  .content_right_service_container {
    width: 100%;

    /* font-size: 15px; */
    letter-spacing: 0.5px;

    line-height: 20px;
  }
  .center_service_text {
    /* font-size: 15px; */
    letter-spacing: 0.5px;

    line-height: 20px;
  }
  .end_of_content_container {
    /* font-size: 15px; */
    letter-spacing: 0.5px;
    line-height: 20px;
    margin: 0;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .content_right_service_container,
  .center_service_text,
  .end_of_content_container {
    font-size: 14px;
  }
  .our_work_read_more_title_text {
    font-size: 30px;
    line-height: 35px;
  }
  .bg_read_more_section {
    min-height: 80vh;
    background-size: cover;
    background-position: right;
  }
  .green_read_more_container_heading {
    padding: 15px;
  }
  .content_service_container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .content_img_container {
    width: 70%;
    margin: 0 auto;
  }
  .under_main_read_more_container {
    width: 380px;
    margin: 0 auto;
  }
  .content_left_service_container {
    width: 100%;
  }
  .content_right_service_container {
    width: 100%;

    /* font-size: 15px; */
    letter-spacing: 0.5px;

    line-height: 20px;
  }
  .center_service_text {
    /* font-size: 15px; */
    letter-spacing: 0.5px;

    line-height: 20px;
  }
  .end_of_content_container {
    /* font-size: 15px; */
    letter-spacing: 0.5px;
    line-height: 20px;
    margin: 0;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .content_right_service_container,
  .center_service_text,
  .end_of_content_container {
    font-size: 16px;
  }
  .under_main_read_more_container {
    width: 700px;
    margin: 0 auto;
  }
  .our_work_read_more_title_text {
    font-size: 50px;
    line-height: 58px;
  }
  .nav_bar_read_more_section_container {
    width: 700px;
    margin: 0 auto;
  }
  .green_read_more_container_heading {
    padding: 20px;
  }

  .bg_read_more_section {
    background-size: cover;
    background-position: unset;
  }
  .content_img_container {
    width: 95%;
  }
  .content_right_service_container {
    /* font-size: 18px; */
  }
  .center_service_text {
    /* font-size: 18px; */

    margin: 11px 0;
  }
  .end_of_content_container {
    /* font-size: 18px; */
    letter-spacing: 0.5px;

    margin: 26px 0 15px;
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  .our_work_read_more_title_text {
    font-size: 55px;
    line-height: 65px;
  }
  .nav_bar_read_more_section_container {
    width: 930px;
    margin: 0 auto;
    padding: 15px 0 0;
  }
  .green_read_more_container_heading {
    padding: 25px;
  }
  .under_main_read_more_container {
    width: 930px;
    margin: 0 auto;
  }
  .content_img_container {
    width: 95%;
  }
  .content_right_service_container {
    font-size: 18px;
  }
  .center_service_text {
    font-size: 18px;

    margin: 11px 0;
  }
  .end_of_content_container {
    font-size: 18px;
    letter-spacing: 0.5px;

    margin: 26px 0 15px;
  }
}

@media (min-width: 1200px) {
  .content_right_service_container,
  .center_service_text,
  .end_of_content_container {
    font-size: 18px;
  }
  .bg_read_more_hero_section_container {
    width: 1100px;
    margin: 0 auto;
  }
  .under_main_read_more_container {
    width: 1000px;
    margin: 0 auto;
  }
  .nav_bar_read_more_section_container {
    padding: 30px 0 0;
    width: 1100px;
    margin: 0 auto;
  }
}

@media (min-width: 1400px) {
  .content_right_service_container,
  .center_service_text,
  .end_of_content_container {
    font-size: 22px;
  }
  .bg_read_more_hero_section_container {
    width: 1300px;
    margin: 0 auto;
  }
  .under_main_read_more_container {
    width: 1200px;
    margin: 0 auto;
  }
  .nav_bar_read_more_section_container {
    padding: 30px 0 0;
    width: 1300px;
    margin: 0 auto;
  }
  .content_img_container {
    width: 564px;
  }
  /* .content_right_service_container {
    font-size: 25px;
    letter-spacing: 0.5px;
  } */
  /* .center_service_text {
    font-size: 25px;
  }
  .end_of_content_container {
    font-size: 25px;
  } */
}
