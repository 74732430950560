@font-face {
  font-family: OswaldLight;
  src: url("../../assets/fonts/Oswald-Light.ttf");
}
@font-face {
  font-family: OswaldReg;
  src: url("../../assets/fonts/Oswald-Regular.ttf");
}

@font-face {
  font-family: OswaldMed;
  src: url("../../assets/fonts/Oswald-Medium.ttf");
}
@font-face {
  font-family: OswaldSemiBold;
  src: url("../../assets/fonts/Oswald-SemiBold.ttf");
}
@font-face {
  font-family: OswaldBold;
  src: url("../../assets/fonts/Oswald-Bold.ttf");
}

/* Poppins  */

@font-face {
  font-family: PoppinsLight;
  src: url("../../assets/fonts/Poppins-Light.ttf");
}
@font-face {
  font-family: PoppinsThin;
  src: url("../../assets/fonts/Poppins-Thin.ttf");
}

@font-face {
  font-family: PoppinsThinItalic;
  src: url("../../assets/fonts/Poppins-ThinItalic.ttf");
}

@font-face {
  font-family: PoppinsRegular;
  src: url("../../assets/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: PoppinsItalic;
  src: url("../../assets/fonts/Poppins-Italic.ttf");
}

@font-face {
  font-family: PoppinsMed;
  src: url("../../assets/fonts/Poppins-Medium.ttf");
}
@font-face {
  font-family: PoppinsMedItalic;
  src: url("../../assets/fonts/Poppins-MediumItalic.ttf");
}
@font-face {
  font-family: PoppinsSemiBold;
  src: url("../../assets/fonts/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: PoppinsBold;
  src: url("../../assets/fonts/Poppins-Bold.ttf");
}
