.green_container_heading_projects1 {
  display: inline-flex;
  padding: 36px;
  text-align: left;
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 52%,
    rgba(27, 94, 32, 1) 38%
  );
}

.search_main_wrapper {
  margin: 0 auto;
  padding: 30px 0;
}
.search_input_icon_container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  padding: 20px 10px;
}
.search_input_icon_container > input {
  border: 0;
  width: 100%;
  outline: none;
  margin: 0 20px;
  font-size: 15px;
}
.search_result_container {
}
.search_grey_container {
  padding: 20px;
  background: rgba(217, 217, 217, 1);
  margin: 20px 0;
}
.search_flex_container {
  display: flex;
  justify-content: space-between;
}
.search_left_container {
  width: 50%;
  color: var(--section-black);
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.info_job_text {
  font-size: 13px;
  /* text-transform: capitalize; */
}
.title_experience_text_wrapper {
}
.title_experience_text_wrapper > strong {
  font-family: poppinsSemiBold;
  text-transform: uppercase;
  font-size: 21px;
}
.title_experience_text_wrapper > p {
  font-family: poppinsMed;
  /* text-transform: capitalize; */
  font-size: 16px;
}
.search_right_container {
  width: 50%;
  color: var(--section-black);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding: 0% 3%; */
  align-items: flex-end;
}
.search_right_container > strong {
  font-family: poppinsSemiBold;
  font-size: 21px;
  text-transform: uppercase;
}
.content_apply_now_btn_container > button {
  background: rgba(27, 94, 32, 1);
  font-family: poppinsSemiBold;
  color: var(--theme-white);
  height: 35px;
  border: 0;
  font-size: 14px;
  width: 13%;
  transition: 1s;
  margin: auto 0 0 0;
}
.content_apply_now_btn_container > button:hover {
  background: rgb(0, 0, 0);

  color: var(--theme-white);
}
.content_apply_now_btn_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.read_more_txt_join {
  font-size: 15px;
  font-family: "PoppinsMed";
  cursor: pointer;
  transition: 0.5s;
}
.read_more_txt_join:hover {
  color: var(--theme-green);
  font-family: poppinsSemiBold;
}
.left_content_apply {
  width: 85%;
}
.title_experience_text_wrapper > strong {
  font-size: 17px;
}

@media (max-width: 767px) {
  .content_apply_now_btn_container > button {
    height: 30px;

    font-size: 11px;
    width: 100%;
  }
  .left_content_apply {
    width: 100%;
  }
  .info_job_text {
    font-size: 10px;
  }
  .read_more_txt_join {
    font-size: 11px;
  }
  .read_more_txt_join {
    font-size: 11px;
    position: relative;
    top: -6px;
  }
  .content_apply_now_btn_container {
    width: 100%;
    flex-direction: column;
  }
  .search_flex_container {
    flex-direction: column;
  }
  .search_main_wrapper {
    width: 94%;
  }
  .search_left_container {
    width: 100%;
  }
  .search_right_container > strong {
    font-size: 13px;
  }
  .search_grey_container {
    padding: 12px;

    margin: 15px 0;
  }
  .title_experience_text_wrapper > p {
    font-size: 13px;
  }
  .search_right_container {
    width: 100%;
    color: var(--section-black);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* padding: 0% 3%; */
    align-items: flex-start;
  }
}

@media (min-width: 767px) and (max-width: 990px) {
  .search_main_wrapper {
    width: 700px;
  }
  .content_apply_now_btn_container > button {
    height: 35px;

    font-size: 12px;
    width: 13%;
  }
  .search_right_container > strong {
    font-size: 15px;
  }
}

@media (min-width: 990px) and (max-width: 1200px) {
  .search_main_wrapper {
    width: 930px;
  }
  .content_apply_now_btn_container > button {
    height: 35px;

    font-size: 12px;
    width: 13%;
  }
  .search_right_container > strong {
    font-size: 15px;
  }
}

@media (min-width: 1200px) {
  .search_main_wrapper {
    width: 1100px;
  }
  .search_right_container > strong {
    font-size: 17px;
  }
}

@media (min-width: 1400px) {
  .search_main_wrapper {
    width: 1300px;
  }
}
