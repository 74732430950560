.contact_form_main_container {
  /* position: relative; */
  background-color: var(--theme-white);
  /* z-index: -1; */
}
.contact_under_form_container {
  margin: 0 auto;
  z-index: 2;
  position: relative;
}
.contact_form_flex_container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  padding: 0 0 0 70px;
}
.contact_left_form_container {
  width: 46%;
  display: flex;
  justify-content: flex-end;
}
.contact_rgt_form_container {
  width: 53%;
  position: relative;
  left: -60px;
  z-index: -1;
}
.form_contact_us {
  width: 94%;
  border: 1px solid #111;
  padding: 30px 40px;
  background: var(--theme-white);
}
.extra_form_content {
  font-size: 14px;
  font-family: "PoppinsRegular";
}
.all_input_container {
}
.img_form_container {
  width: 85%;
}

.img_form_container > img {
  width: 100%;
}
.submit_btn_form {
  width: 34%;
  height: 48px;
  background: rgb(27 94 32);
  color: var(--theme-grey);
  border: 0;
  letter-spacing: 3px;
  margin: 27px auto 0;
  font-size: 18px;
  font-family: "PoppinsRegular";
}
.submit_btn_form:hover {
  color: var(--section-black);
}
.green_rect_container {
  position: absolute;
  top: 41%;
  background-color: var(--theme-green);
  height: 120px;
  width: 100%;
  z-index: 0;
}

@media (max-width: 299px) {
  .contact_rgt_form_container {
    width: 0;
    position: relative;
    left: 0;
    z-index: -1;
  }
  .submit_btn_form {
    width: 60%;
    height: 38px;

    letter-spacing: 3px;
    margin: 14px auto 0;
    font-size: 16px;
    border-radius: 4px;
  }
  .contact_under_form_container {
    width: 94%;
  }
  .contact_form_flex_container {
    padding: 20px 0 0 0px;
    flex-direction: column;
  }
  .contact_left_form_container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .form_contact_us {
    width: 100%;

    padding: 20px 20px;
  }
  .extra_form_content {
    font-size: 12px;
    font-family: "PoppinsRegular";
    line-height: 16px;
  }
}

@media (min-width: 300px) and (max-width: 399px) {
  .contact_rgt_form_container {
    width: 0;
    position: relative;
    left: 0;
    z-index: -1;
  }
  .submit_btn_form {
    width: 60%;
    height: 38px;

    letter-spacing: 3px;
    margin: 14px auto 0;
    font-size: 14px;
    border-radius: 4px;
  }
  .contact_under_form_container {
    width: 290px;
  }
  .contact_form_flex_container {
    padding: 20px 0 0 0px;
    flex-direction: column;
  }
  .contact_left_form_container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .form_contact_us {
    width: 100%;

    padding: 20px 20px;
  }
  .extra_form_content {
    font-size: 12px;
    font-family: "PoppinsRegular";
    line-height: 16px;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .contact_rgt_form_container {
    width: 0;
    position: relative;
    left: 0;
    z-index: -1;
  }
  .submit_btn_form {
    width: 40%;
    height: 38px;
    letter-spacing: 3px;
    margin: 14px auto 0;
    font-size: 15px;
    border-radius: 4px;
  }
  .contact_under_form_container {
    width: 390px;
  }
  .contact_form_flex_container {
    padding: 20px 0 0 0px;
    flex-direction: column;
  }
  .contact_left_form_container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .form_contact_us {
    width: 100%;
    padding: 26px 30px;
  }
  .extra_form_content {
    font-size: 14px;
    line-height: 20px;
    margin: 0px 0 10px;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .green_rect_container {
    top: 46%;

    height: 90px;
  }
  .contact_left_form_container {
    width: 50%;
    display: flex;
    justify-content: flex-end;
  }
  .submit_btn_form {
    width: 55%;
    height: 40px;

    border: 0;
    letter-spacing: 2px;
    margin: 8px auto 0;
    font-size: 15px;
  }
  .contact_under_form_container {
    width: 700px;
  }
  .contact_form_flex_container {
    padding: 30px 0 39px 20px;
  }
  .form_contact_us {
    width: 100%;

    padding: 21px 27px;
  }
  .extra_form_content {
    font-size: 12px;

    line-height: 15px;
  }
  .contact_rgt_form_container {
    width: 50%;
    position: relative;
    left: -51px;
  }
  .img_form_container {
    width: 100%;
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  .green_rect_container {
    top: 46%;

    height: 90px;
  }
  .contact_left_form_container {
    width: 45%;
    display: flex;
    justify-content: flex-end;
  }
  .submit_btn_form {
    width: 55%;
    height: 40px;

    border: 0;
    letter-spacing: 2px;
    margin: 8px auto 0;
    font-size: 15px;
  }
  .contact_under_form_container {
    width: 930px;
  }
  .contact_form_flex_container {
    padding: 30px 0 39px 20px;
  }
  .form_contact_us {
    width: 100%;

    padding: 21px 27px;
  }
  .extra_form_content {
    font-size: 12px;

    line-height: 15px;
  }
  .contact_rgt_form_container {
    width: 50%;
    position: relative;
    left: -51px;
  }
  .img_form_container {
    width: 81%;
  }
}

@media (min-width: 1200px) {
  .contact_under_form_container {
    width: 1100px;
  }
}

@media (min-width: 1400px) {
  .extra_form_content {
    font-size: 17px;

    line-height: 24px;
    margin: 0 0 14px;
  }
  .form_contact_us {
    width: 100%;
    padding: 40px 50px;
  }
  .form_contact_us {
    width: 100%;
  }
  .contact_rgt_form_container {
    width: 50%;
    position: relative;
    left: -60px;
    z-index: -1;
  }
  .contact_under_form_container {
    width: 1300px;
  }
  .img_form_container {
    width: 73%;
  }
}
