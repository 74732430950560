.company_about_main_section_container {
  padding: 60px 0;
  background: var(--theme-white);
}
.company_under_main_container {
  margin: 0 auto;
}
.compnay_flex_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.company_one_container {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.company_green_container {
  padding: 30px 0 100px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 25%,
    rgba(27, 94, 32, 1) 20%
  );
  display: flex;
  text-align: center;
  color: var(--theme-white);
  flex-direction: column;
}
.company_circle_container {
  background: var(--theme-white);
  box-shadow: 1px 0px 15px 2px rgb(0 0 0 / 15%);
  border-radius: 50%;
  width: 300px;
  height: 300px;
  display: flex;

  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.company_logo_ {
  width: 88%;
}
.company_title_text {
  font-family: poppinsMed;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 2px;
  margin: 35px 0;
}
.company_two_container {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 220px 60px 0;
}
.company_logo_flex_container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.extra_text_about_company {
  font-family: "PoppinsRegular";
  line-height: 25px;
  font-size: 18px;
  width: 90%;
  margin: 0px auto 48px;
}
.about_title_text_me {
  font-size: 60px;
  font-family: "OswaldSemiBold";
  color: var(--section-black);
  line-height: 75px;
}
.about_extra_paragraph_text {
  color: rgba(126, 126, 126, 1);
  font-family: "PoppinsRegular";
  font-size: 17px;
  line-height: 28px;
}
.margin_container {
  margin: 30px 0 0;
}
.read_more_btn_style {
  font-family: PoppinsSemiBold;
  color: var(--theme-grey);
  /* outline: none; */
  border: 0;
  margin: 0;
  background: transparent;
}
.read_more_btn_style:hover {
  color: var(--theme-green);
}
.two_container_about {
  display: flex;
  align-items: center;
  margin: 0 auto;
}
.first_container_desgin {
  display: flex;
  width: 50%;
}
.grow_img_container {
  width: 70%;
  margin: 0 auto;
}
.grow_img {
  width: 100%;
}
.first_container_desgin1 {
  display: flex;
  width: 50%;
  flex-direction: column;
}

@media (max-width: 399px) {
  .two_container_about {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 94%;
    margin: 0 auto;
  }
  .first_container_desgin {
    display: flex;
    width: 100%;
  }
  .grow_img_container {
    width: 95%;
    margin: 0 auto;
  }
  .grow_img {
    width: 100%;
  }
  .first_container_desgin1 {
    display: flex;
    width: 95%;
    flex-direction: column;
  }
  .read_more_btn_style {
    font-size: 14px;
  }
  .margin_left_right {
    width: 94%;
    margin: 0 auto;
  }
  .company_green_container {
    padding: 0px 0 25px;
  }
  .company_about_main_section_container {
    padding: 30px 0;
  }
  .compnay_flex_container {
    display: flex;
    flex-direction: column;
  }
  .company_one_container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .company_circle_container {
    width: 150px;
    height: 150px;
  }
  .company_logo_ {
    width: 80%;
  }
  .company_title_text {
    font-size: 21px;
    line-height: 28px;
    letter-spacing: 2px;
    margin: 19px 0 12px;
  }
  .extra_text_about_company {
    line-height: 18px;
    font-size: 12px;
    width: 94%;
    margin: 0px auto 22px;
  }
  .company_social_logo_container {
    width: 20px;
  }
  .margin_container {
    margin: 13px auto 0;
    width: 90%;
  }
  .about_extra_paragraph_text {
    font-size: 13px;
    line-height: 19px;
    text-align: left;
  }
  .company_two_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 0 0;
  }
  .about_title_text_me {
    font-size: 0;
    line-height: 0;
    text-align: center;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .two_container_about {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 390px;
    margin: 0 auto;
  }
  .first_container_desgin {
    display: flex;
    width: 100%;
  }
  .grow_img_container {
    width: 95%;
    margin: 0 auto;
  }
  .grow_img {
    width: 100%;
  }
  .first_container_desgin1 {
    display: flex;
    width: 95%;
    flex-direction: column;
  }
  .read_more_btn_style {
    font-size: 16px;
  }
  .company_green_container {
    padding: 0px 0 30px;
  }
  .company_about_main_section_container {
    padding: 30px 0;
  }
  .compnay_flex_container {
    display: flex;
    flex-direction: column;
  }
  .company_one_container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .company_circle_container {
    width: 160px;
    height: 160px;
  }
  .company_logo_ {
    width: 85%;
  }
  .company_title_text {
    font-size: 28px;
    line-height: 23px;
    letter-spacing: 2px;
    margin: 30px 0;
  }
  .extra_text_about_company {
    line-height: 23px;
    font-size: 15px;
    width: 380px;
    margin: 0px auto 22px;
  }
  .company_social_logo_container {
    width: 30px;
  }
  .margin_container {
    margin: 11px auto 0;
    width: 380px;
  }
  .about_extra_paragraph_text {
    font-size: 14px;
    line-height: 22px;
  }
  .company_two_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 0 0;
  }
  .about_title_text_me {
    font-size: 0;
    line-height: 0;
    text-align: center;
  }
  .company_logo_flex_container {
    margin: 15px auto 0;
    width: 380px;
  }
  .margin_left_right {
    width: 380px;
    margin: 0 auto;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .two_container_about {
    display: flex;
    align-items: center;
    width: 700px;
    margin: 10px auto;
  }
  .first_container_desgin {
    display: flex;
    width: 50%;
  }
  .grow_img_container {
    width: 90%;
    margin: 0 auto;
  }
  .grow_img {
    width: 100%;
  }
  .first_container_desgin1 {
    display: flex;
    width: 50%;
    flex-direction: column;
  }
  .read_more_btn_style {
    font-size: 16px;
  }
  .margin_left_right {
    width: 700px;
    margin: 20px auto 0;
  }
  .company_social_logo_container {
    width: 24px;
  }
  .company_green_container {
    padding: 0px 0 60px;
  }
  .company_circle_container {
    width: 200px;
    height: 200px;
  }
  .company_title_text {
    font-size: 29px;
    line-height: 30px;
    margin: 25px 0 15px;
  }
  .extra_text_about_company {
    line-height: 25px;
    font-size: 15px;

    margin: 0px auto 34px;
  }
  .margin_container {
    margin: 15px 0 0;
  }
  .about_extra_paragraph_text {
    font-size: 14px;
    line-height: 21px;
  }
  .compnay_flex_container {
    width: 700px;
    margin: 0 auto;
  }
  .about_title_text_me {
    font-size: 40px;

    line-height: 45px;
  }
  .company_two_container {
    padding: 136px 18px 0;
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  .two_container_about {
    display: flex;
    align-items: center;
    width: 930px;
    margin: 10px auto;
  }
  .first_container_desgin {
    display: flex;
    width: 50%;
  }
  .grow_img_container {
    width: 70%;
    margin: 0 auto;
  }
  .grow_img {
    width: 100%;
  }
  .first_container_desgin1 {
    display: flex;
    width: 48%;
    flex-direction: column;
    margin: 0 0 0 auto;
  }
  .read_more_btn_style {
    font-size: 18px;
  }
  .company_two_container {
    padding: 168px 25px 0;
  }
  .compnay_flex_container {
    width: 930px;
    margin: 0 auto;
  }
  .company_title_text {
    font-size: 35px;
    line-height: 40px;
    margin: 30px 0 25px;
  }
  .extra_text_about_company {
    line-height: 29px;
    font-size: 17px;
    margin: 0px auto 31px;
  }
  .company_social_logo_container {
    width: 28px;
  }
  .company_green_container {
    padding: 0px 0 45px;
  }
  .about_extra_paragraph_text {
    font-size: 16px;
    line-height: 26px;
  }
  .about_title_text_me {
    font-size: 45px;
    line-height: 50px;
  }
  .margin_container {
    margin: 22px 0 0;
  }
  .margin_left_right {
    width: 930px;
    margin: 0 auto;
    font-size: 13px;
    line-height: 26px;
  }
}

@media (min-width: 1200px) {
  .two_container_about {
    display: flex;
    align-items: center;
    width: 1100px;
    margin: 10px auto 0;
  }
  .first_container_desgin {
    display: flex;
    width: 50%;
  }
  .grow_img_container {
    width: 70%;
    margin: 0 auto;
  }
  .grow_img {
    width: 100%;
  }
  .first_container_desgin1 {
    display: flex;
    width: 50%;
    flex-direction: column;
  }
  .read_more_btn_style {
    font-size: 20px;
  }
  .margin_left_right {
    width: 1100px;
    margin: 6px auto 0;
    font-size: 16px;
    line-height: 25px;
  }
  .company_logo_ {
    width: 83%;
  }
  .margin_container {
    margin: 15px 0 0;
  }
  .company_under_main_container {
    width: 1100px;
  }
  .extra_text_about_company {
    line-height: 29px;
    font-size: 19px;
    width: 89%;
    margin: 0px auto 48px;
  }
  .company_social_logo_container {
    width: 40px;
  }
  .company_green_container {
    padding: 0px 0 100px;
  }
  .company_circle_container {
    width: 250px;
    height: 250px;
  }
}

@media (min-width: 1400px) {
  .two_container_about {
    display: flex;
    align-items: center;
    width: 1300px;
    margin: 20px auto 0;
  }
  .first_container_desgin {
    display: flex;
    width: 50%;
  }
  .grow_img_container {
    width: 70%;
    margin: 0 auto;
  }
  .grow_img {
    width: 100%;
  }
  .first_container_desgin1 {
    display: flex;
    width: 50%;
    flex-direction: column;
  }
  .company_title_text {
    margin: 25px 0 20px;
  }
  .margin_left_right {
    width: 1300px;
    margin: 10px auto 0;
    font-size: 16px;
    line-height: 25px;
  }
  .company_under_main_container {
    width: 1300px;
  }
  .company_green_container {
    padding: 0px 0 80px;
  }
  .about_extra_paragraph_text {
    font-size: 19px;
    line-height: 30px;
  }
  .company_two_container {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 225px 27px 0px;
  }
}
