.display_work_section_main_container {
  background: url("../../assets/work/responsive_bg.svg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: inherit;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  position: relative;
  background-color: var(--theme-white);
}
.under_sec_bg_container {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.16) 0%,
    rgba(27, 94, 32, 0.15) 100%
  );
  position: relative;
}
.responsive_img_container {
  margin: 40px auto;
  width: 75%;
}
.responsive_img_self {
  width: 100%;
}
.extra_work_text {
  font-family: PoppinsRegular;
  font-size: 18px;
  color: rgb(0 0 0 / 80%);
  line-height: 22px;
  margin: 10px 0;
}
.mobiles_img_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.first_slot_container {
  width: 33.33%;
}
.mobile_first_img_container {
  width: 100%;
}
.mobile_sec_img_container {
  width: 100%;
}
.mobile_third_img_container {
  width: 100%;
}
.mob_img_one {
  width: 100%;
}
.display_heading_title_container {
}

@media (max-width: 299px) {
  .mobile_first_img_container {
    width: 100%;
  }
  .mobile_sec_img_container {
    width: 100%;
  }
  .mobile_third_img_container {
    width: 100%;
  }
  .headaer_hybrid_text_container {
    margin: 30px 0;
  }
  .display_work_section_main_container {
    width: 100%;
  }
  .under_sec_bg_container {
    padding: 30px 0;
  }
  .display_under_work_section_container {
    width: 94%;
    margin: 0 auto;
  }
  .extra_work_text {
    font-size: 14px;

    line-height: 19px;
    margin: 15px 0;
  }
  .responsive_img_container {
    margin: 40px auto;
    width: 92%;
  }
}

@media (min-width: 300px) and (max-width: 399px) {
  .mobile_first_img_container {
    width: 100%;
  }
  .mobile_sec_img_container {
    width: 100%;
  }
  .mobile_third_img_container {
    width: 100%;
  }
  .headaer_hybrid_text_container {
    margin: 30px 0;
  }
  .display_work_section_main_container {
    width: 100%;
  }
  .under_sec_bg_container {
    padding: 30px 0;
  }
  .display_under_work_section_container {
    width: 94%;
    margin: 0 auto;
  }
  .extra_work_text {
    font-size: 15px;
    line-height: 23px;
    margin: 15px 0;
  }
  .responsive_img_container {
    margin: 30px auto;
    width: 92%;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .mobile_first_img_container {
    width: 100%;
  }
  .mobile_sec_img_container {
    width: 100%;
  }
  .mobile_third_img_container {
    width: 100%;
  }
  .headaer_hybrid_text_container {
    margin: 30px 0;
  }
  .display_work_section_main_container {
    width: 100%;
  }
  .under_sec_bg_container {
    padding: 30px 0;
  }
  .display_under_work_section_container {
    width: 380px;
    margin: 0 auto;
  }
  .extra_work_text {
    font-size: 15px;
    line-height: 23px;
    margin: 15px 0;
  }
  .responsive_img_container {
    margin: 30px auto;
    width: 92%;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .mobile_first_img_container {
    width: 100%;
  }
  .mobile_sec_img_container {
    width: 100%;
  }
  .mobile_third_img_container {
    width: 100%;
  }
  
  .display_heading_title_container {
    width: 90%;
  }
  .headaer_hybrid_text_container {
    margin: 0px 0 30px;
  }
  .display_work_section_main_container {
    width: 100%;
  }
  .under_sec_bg_container {
    padding: 30px 0;
  }
  .display_under_work_section_container {
    width: 700px;
    margin: 0 auto;
  }
  .extra_work_text {
    font-size: 15px;
    line-height: 23px;
    margin: 15px 0;
  }
  .responsive_img_container {
    margin: 40px auto;
    width: 80%;
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  .mobile_first_img_container {
    width: 100%;
  }
  .mobile_sec_img_container {
    width: 100%;
  }
  .mobile_third_img_container {
    width: 100%;
  }
  .display_heading_title_container {
    width: 90%;
  }
  .headaer_hybrid_text_container {
    margin: 0px 0 30px;
  }
  .display_work_section_main_container {
    width: 100%;
  }
  .under_sec_bg_container {
    padding: 30px 0;
  }
  .display_under_work_section_container {
    width: 930px;
    margin: 0 auto;
  }
  .extra_work_text {
    font-size: 17px;
    line-height: 25px;
    margin: 15px 0;
  }
  .responsive_img_container {
    margin: 40px auto;
    width: 80%;
  }
}

@media (min-width: 1200px) {
  .mobile_first_img_container {
    width: 100%;
  }
  .mobile_sec_img_container {
    width: 89%;
  }
  .mobile_third_img_container {
    width: 86%;
  }
  .mobile_first_img_container {
    width: 80%;
    margin: 0 auto;
  }
  .display_under_work_section_container {
    width: 1100px;
    padding: 60px 0;
    margin: 0 auto;
  }
  .display_heading_title_container {
    width: 90%;
  }
  .headaer_hybrid_text_container {
    margin: 0px 0 30px;
  }
  .display_work_section_main_container {
    width: 100%;
  }
  .under_sec_bg_container {
    padding: 30px 0;
  }

  .extra_work_text {
    font-size: 17px;
    line-height: 25px;
    margin: 15px 0;
  }
  .responsive_img_container {
    margin: 40px auto;
    width: 75%;
  }
}
