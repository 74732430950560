.footer_two_part_container {
  background: url("../../assets/bg-footer.jpg");
  display: flex;
  flex-direction: column;
  width: 55%;
  background-origin: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
}

.sec_part_content_container {
}

.footer_img_container {
  /* background: linear-gradient(
    90deg,
    #1b5e20 0%,
    rgba(255, 255, 255, 0.26) 95.13%
  ); */
}
.two_footer_part_content {
  color: var(--theme-white);
}
.extra_text_of_title_footer {
  font-family: "PoppinsMed";
  font-size: 18px;
  line-height: 27px;
}
.all_input_container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.input_text_footer {
  width: 100%;
  height: 45px;
  font-family: "PoppinsRegular";
  font-size: 15px;
  outline: none;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.62);
  margin: 15px 0;
}
.text_area_container {
  width: 100%;
  font-family: "PoppinsRegular";
  font-size: 15px;
  outline: none;
  padding: 11px 11px;
  margin: 15px 0;
  color: rgba(0, 0, 0, 0.62);
}
.submit_btn {
  width: 34%;
  height: 48px;
  background: var(--theme-white);
  color: var(--theme-green);
  cursor: pointer;
  letter-spacing: 3px;
  border: 1px solid var(--theme-green);
  font-size: 18px;
  font-family: "PoppinsRegular";
}
.absolute_container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 1100px;
  margin: 0 auto;
}
.flex_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px;
}
.left_footer_container {
  width: 50%;
  color: var(--theme-white);
}
.right_footer_container {
  width: 50%;
}
.created_by_text {
  font-family: OswaldMed;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 1px;
  text-align: right;
  margin: 130px 79px 0 0;
}

@media (max-width: 299px) {
  .created_by_text {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0px;

    margin: 70px 0px 0 0;
  }

  .submit_btn {
    margin: 10px 0;
    width: 50%;
    height: 40px;

    font-size: 14px;
  }

  .input_text_footer {
    height: 40px;

    font-size: 13px;

    padding: 0 11px;

    margin: 10px 0;
  }
  .text_area_container {
    font-size: 12px;

    padding: 7px 9px;
    margin: 10px 0;
  }

  .extra_text_of_title_footer {
    font-size: 14px;
    line-height: 19px;
  }
  .absolute_container {
    width: 100%;
  }
  .flex_container {
    display: flex;
    flex-direction: column-reverse;
  }
  .left_footer_container {
    width: 100%;
  }
  .right_footer_container {
    width: 100%;
  }
}

@media (min-width: 300px) and (max-width: 399px) {
  .created_by_text {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0px;

    margin: 70px 0px 0 0;
  }

  .submit_btn {
    margin: 10px 0;
    width: 50%;
    height: 40px;

    font-size: 14px;
  }

  .input_text_footer {
    height: 40px;

    font-size: 13px;

    padding: 0 11px;

    margin: 10px 0;
  }
  .text_area_container {
    font-size: 12px;

    padding: 7px 9px;
    margin: 10px 0;
  }

  .extra_text_of_title_footer {
    font-size: 14px;
    line-height: 19px;
  }
  .absolute_container {
    width: 280px;
  }
  .flex_container {
    display: flex;
    flex-direction: column-reverse;
    padding: 20px 0 0 0;
  }
  .left_footer_container {
    width: 100%;
  }
  .right_footer_container {
    width: 100%;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .created_by_text {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0px;

    margin: 70px 0px 0 0;
  }
  .submit_btn {
    margin: 10px 0;
    width: 37%;
    height: 40px;
    font-size: 15px;
  }

  .input_text_footer {
    height: 40px;

    font-size: 13px;

    padding: 0 11px;

    margin: 10px 0;
  }
  .text_area_container {
    font-size: 12px;

    padding: 7px 9px;
    margin: 10px 0;
  }

  .extra_text_of_title_footer {
    font-size: 14px;
    line-height: 19px;
  }
  .absolute_container {
    width: 380px;
  }
  .flex_container {
    display: flex;
    flex-direction: column-reverse;
    padding: 20px 0 0 0;
  }
  .left_footer_container {
    width: 100%;
  }
  .right_footer_container {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 990px) {
  .input_text_footer {
    font-size: 14px;
  }
  .extra_text_of_title_footer {
    font-size: 15px;
    line-height: 22px;
  }
  .absolute_container {
    width: 700px;
  }
  .flex_container {
    padding: 20px 0;
  }

  .footer_two_part_container {
    width: 60%;
  }

  .created_by_text {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 1px;
    margin: 167px 59px 0 0;
  }
  .text_area_container {
    font-size: 14px;
  }
  .submit_btn {
    width: 40%;
    margin: 13px 0 0;
    height: 48px;
    letter-spacing: 3px;
    font-size: 16px;
  }

  .absolute_container {
    width: 700px;
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  .input_text_footer {
    font-size: 15px;
  }
  .extra_text_of_title_footer {
    font-size: 17px;
    line-height: 24px;
  }
  .absolute_container {
    width: 930 px;
  }
  .flex_container {
    padding: 30px 0;
  }

  .footer_two_part_container {
    width: 60%;
  }

  .created_by_text {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 1px;
    margin: 159px 89px 0 0;
  }
  .text_area_container {
    font-size: 15px;
  }
  .submit_btn {
    width: 32%;
    margin: 13px 0 0;
    height: 48px;
    letter-spacing: 3px;
    font-size: 17px;
  }

  .absolute_container {
    width: 930px;
  }
}

@media (min-width: 1200px) {
  .input_text_footer {
    font-size: 16px;
  }
  .extra_text_of_title_footer {
    font-size: 15px;
    line-height: 22px;
  }
  .absolute_container {
    width: 930 px;
  }
  .flex_container {
    padding: 30px 0;
  }

  .footer_two_part_container {
    width: 60%;
  }

  .created_by_text {
    font-size: 19px;
    line-height: 21px;
    letter-spacing: 1px;
    margin: 125px 107px 0 0;
  }
  .text_area_container {
    font-size: 16px;
  }
  .submit_btn {
    width: 32%;
    margin: 13px 0 0;
    height: 48px;
    letter-spacing: 3px;
    font-size: 19px;
  }

  .absolute_container {
    width: 1100px;
  }
}

@media (min-width: 1400px) {
  .input_text_footer {
    font-size: 17px;
    height: 50px;
  }
  .extra_text_of_title_footer {
    font-size: 18px;
    line-height: 28px;
  }
  .absolute_container {
    width: 930 px;
  }
  .flex_container {
    padding: 30px 0;
  }

  .footer_two_part_container {
    width: 60%;
  }

  .created_by_text {
    font-size: 19px;
    line-height: 21px;
    letter-spacing: 1px;
    margin: 125px 154px 0 0;
  }
  .text_area_container {
    font-size: 16px;
  }
  .submit_btn {
    width: 26%;
    margin: 13px 0 0;
    height: 50px;
    letter-spacing: 3px;
    font-size: 19px;
    transition: var(--transition);
  }
  .submit_btn:hover {
    background: var(--section-black);
    color: #ffffff;
  }

  .absolute_container {
    width: 1300px;
  }
}
