.six_box_career_container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}
.six_box_container {
  width: 33.33%;
  /* border: 1px solid red; */
  /* background: #f4f4f4; */
  color: var(--section-black);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2% 2%;
  text-align: center;
  gap: 10px;
}
.img_container_six {
}
.six_box_title {
  font-family: "OswaldSemiBold";
  text-transform: capitalize;
  font-size: 24px;
}
.six_box_subtitle {
  font-family: "PoppinsRegular";
  font-size: 17px;
}

@media (max-width: 767px) {
  .img_container_six {
    width: 16%;
  }
  .img_container_six {
  }
  .six_box_container {
    width: 100%;
    padding: 10% 2%;
  }
  .six_box_title {
    font-size: 21px;
  }
  .six_box_subtitle {
    font-size: 13px;
    line-height: 18px;
    width: 90%;
    margin: 0 auto;
  }
}

@media (min-width: 767px) {
  .six_box_container {
    padding: 3% 2%;

    gap: 4px;
  }
  .img_container_six {
    width: 17%;
  }
  .six_box_title {
    font-size: 17px;
  }
  .six_box_subtitle {
    font-size: 11px;
  }
}

@media (min-width: 990px) {
  .six_box_container {
    padding: 3% 2%;

    gap: 5px;
  }
  .img_container_six {
    width: 17%;
  }
  .six_box_title {
    font-size: 18px;
  }
  .six_box_subtitle {
    font-size: 12px;
  }
}

@media (min-width: 1200px) {
  .six_box_container {
    padding: 3% 2%;

    gap: 5px;
  }
  .img_container_six {
    width: 17%;
  }
  .six_box_title {
    font-size: 20px;
  }
  .six_box_subtitle {
    font-size: 14px;
  }
}

@media (min-width: 1400px) {
  .six_box_container {
    padding: 3% 2%;

    gap: 5px;
  }
  .img_container_six {
    width: 17%;
  }
  .six_box_title {
    font-size: 23px;
  }
  .six_box_subtitle {
    font-size: 17px;
  }
}
