.bg_section_container {
  /* background: url("../../assets/development_bg.svg") no-repeat fixed center; */
  background-image: url("../../assets/development_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  /* max-width: 1400px; */
  margin: 0 auto;
  min-height: 100vh;
  position: relative;
}
.bg_hero_section_container {
}
.overlay_color {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.5s;
}
.logo_ds_menu_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  /* max-width: 1400px;
    margin:0 auto; */
}
.logo_ds_container {
  width: 100px;
}
.developersquad_logo {
  width: 100%;
}

.menu_btn {
  background: var(--theme-green);
  color: var(--theme-white);
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: 0;
  transition: var(--transition);
}
.menu_btn:hover,
.menu_btn:active {
  color: var(--theme-green);
  background: var(--theme-white);
}
.menu_icon {
  font-size: 20px;
}

.development_text_container {
  background: var(--theme-green);
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  width: 500px;
  position: absolute;
  bottom: 0;
  left: auto;
  padding: 45px;
}
.development_text_container_right {
  display: none;
}
.development_text {
  font-size: 70px;
  font-family: OswaldSemiBold;
  color: var(--theme-white);
  line-height: 80px;
  text-transform: uppercase;
}
.development_extra_content {
  color: var(--theme-white);
  font-family: PoppinsLight;
  font-size: 17px;
  line-height: 25px;
  width: 100%;
  letter-spacing: 1px;
  margin: 25px 0;
}
.contact_us_btn {
  width: 50%;
  height: 50px;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: var(--theme-green);
  background: var(--theme-white);
  border: 0;
  font-family: PoppinsRegular;
  transition: var(--transition);
}
.development_text_container > button:hover,
.development_text_container > button:active {
  background: var(--section-black);
  color: var(--theme-white);
}

/* side bar */

#opensidenav {
  width: 300px;
}
#mySidenav {
  width: 0;
}
.side_nav_container {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 6;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: all 0.3s ease-in-out;
  padding-top: 75px;
}
.link_hidden_text {
  visibility: hidden;
}
.link_menu_text {
  padding: 0px 8px 5px 32px;

  text-decoration: none;
  font-size: 30px;
  color: var(--theme-white);
  display: block;
  font-family: OswaldSemiBold;
  text-transform: uppercase;
  transition: all 0.1s ease-in-out;
}
.close_btn_menu {
  color: var(--theme-white);
}
.close_btn_menu:hover,
.link_menu_text:hover {
  color: var(--theme-green);
}

.side_nav_container .close_btn_menu {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 50px;
  margin-left: 50px;
}

.fade-out-right-reverse {
  -webkit-animation: fade-out-right-reverse 0.5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s alternate-reverse both;
  animation: fade-out-right-reverse 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    0.3s alternate-reverse both;
}

@-webkit-keyframes fade-out-right-reverse {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
}
@keyframes fade-out-right-reverse {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
}

@media screen and (max-height: 450px) {
  .side_nav_container {
    padding-top: 15px;
  }
  .side_nav_container a {
    font-size: 18px;
  }
}

@media (max-width: 299px) {
  .logo_ds_container {
    width: 80px;
  }
  .development_text_container {
    width: 90%;
    bottom: 0;
    left: auto;
    padding: 17px;
    margin: 0 14px 0px;
  }
  .development_text {
    font-size: 27px;
    line-height: 32px;
  }
  .development_extra_content {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.5px;
    margin: 10px 0 15px;
  }
  .contact_us_btn {
    width: 60%;
    height: 39px;
    font-size: 13px;
    letter-spacing: 1px;
  }
  .bg_section_container {
    min-height: 80vh;
  }
  .logo_ds_menu_container {
    padding: 15px 10px;
  }
  .menu_btn {
    width: 30px;
    height: 30px;
  }
  .menu_icon {
    font-size: 16px;
  }
  .side_nav_container .close_btn_menu {
    right: 33px;
    font-size: 50px;
    margin-left: 47px;
  }
  .link_menu_text {
    padding: 0px 10px 9px 25px;

    font-size: 25px;
  }
}

@media (min-width: 300px) and (max-width: 399px) {
  .logo_ds_container {
    width: 80px;
  }
  .development_text_container {
    width: 80%;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 19px;
    margin: 0 auto;
  }
  .development_text {
    font-size: 24px;
    line-height: 27px;
  }
  .development_extra_content {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.6px;
    margin: 10px 0 14px;
  }
  .contact_us_btn {
    width: 60%;
    height: 39px;
    font-size: 13px;
    letter-spacing: 1px;
  }
  .bg_section_container {
    min-height: 80vh;
  }
  .logo_ds_menu_container {
    padding: 15px 10px;
    width: 280px;
    margin: 0 auto;
  }
  .menu_btn {
    width: 30px;
    height: 30px;
  }
  .menu_icon {
    font-size: 16px;
  }
  .side_nav_container .close_btn_menu {
    right: 33px;
    font-size: 50px;
    margin-left: 47px;
  }
  .link_menu_text {
    padding: 0px 10px 9px 25px;

    font-size: 25px;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .logo_ds_container {
    width: 80px;
  }
  .development_text_container {
    width: 380px;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 25px 30px;
    margin: 0 auto;
  }
  .development_text {
    font-size: 29px;
    line-height: 36px;
  }
  .development_extra_content {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.7px;
    margin: 11px 0 11px;
  }
  .contact_us_btn {
    width: 45%;
    height: 45px;
    font-size: 15px;
    letter-spacing: 1px;
  }
  .bg_section_container {
    min-height: 80vh;
    background-size: cover;
    background-position: right;
  }
  .logo_ds_menu_container {
    padding: 15px 0px;
    width: 380px;
    margin: 0 auto;
  }
  .menu_btn {
    width: 30px;
    height: 30px;
  }
  .menu_icon {
    font-size: 16px;
  }
  .side_nav_container .close_btn_menu {
    right: 33px;
    font-size: 50px;
    margin-left: 47px;
  }
  .link_menu_text {
    padding: 0px 10px 9px 25px;

    font-size: 25px;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .development_extra_content {
    font-size: 15px;
    line-height: 21px;
    width: 100%;
    letter-spacing: 0.5px;
    margin: 10px 0 17px;
  }
  .nav_bar_under_text_container {
    width: 700px;
    margin: 0 auto;
  }
  .development_text {
    font-size: 34px;
    line-height: 42px;
  }
  .development_text_container {
    width: 350px;
    position: absolute;
    bottom: 0;
    left: auto;
    padding: 25px;
  }
  .bg_section_container {
    background-size: cover;
    background-position: unset;
  }
  .logo_ds_menu_container {
    width: 700px;
    margin: 0 auto;
    padding: 40px 0;
  }
  .contact_us_btn {
    width: 56%;
    height: 45px;
    font-size: 15px;
    letter-spacing: 3px;
    border: 0;
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  .logo_ds_container {
    width: 85px;
  }
  .nav_bar_under_text_container {
    width: 930px;
    margin: 0 auto;
    padding: 15px 0 0;
  }
  .development_text_container {
    width: 465px;

    padding: 35px 32px;
  }
  .development_text {
    font-size: 48px;
    line-height: 50px;
  }
  .development_extra_content {
    font-size: 18px;
    line-height: 24px;

    letter-spacing: 1px;
    margin: 15px 0;
  }
}

@media (min-width: 1200px) {
  .development_text_container {
    background: var(--theme-green);

    width: 450px;

    padding: 45px 30px;
  }
  .bg_hero_section_container {
    width: 1100px;
    margin: 0 auto;
  }
  .nav_bar_under_text_container {
    padding: 30px 0 0;
    width: 1100px;
    margin: 0 auto;
  }
  .development_extra_content {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 1px;
    margin: 15px 0 20px;
  }
  .development_text {
    font-size: 44px;
    line-height: 50px;
  }
}

@media (min-width: 1400px) {
  .development_text_container {
    background: var(--theme-green);

    width: 510px;

    padding: 45px 40px;
  }
  .bg_hero_section_container {
    width: 1300px;
    margin: 0 auto;
  }
  .nav_bar_under_text_container {
    padding: 30px 0 0;
    width: 1300px;
    margin: 0 auto;
  }
  .development_extra_content {
    font-size: 21px;
    line-height: 31px;
    letter-spacing: 0.8px;
    margin: 20px 0 25px;
  }
  .development_text {
    font-size: 53px;
    line-height: 59px;
  }
  .contact_us_btn {
    width: 44%;
    height: 53px;
    font-size: 18px;
  }
}
