.apply_now_form_container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.input_label_field_container {
  display: flex;
  flex-direction: column;
}
.input_label_field_container > label {
  font-family: "PoppinsSemiBold";
  font-size: 15px;
}
.input_label_field_container > input {
  outline: none;
  height: 30px;
  padding: 5px;
  font-family: "PoppinsRegular";
  font-size: 13px;
}
.text_area_form {
  outline: none;

  padding: 5px;
  font-family: "PoppinsRegular";
  font-size: 14px;
  width: 100%;
}
.submit_btn_apply {
  margin: 0 auto;
  background: rgba(27, 94, 32, 1);
  font-family: poppinsSemiBold;
  color: var(--theme-white);
  height: 35px;
  border: 0;
  font-size: 14px;
  width: 20%;
  border-radius: 3px;
  transition: 1s;
}
.submit_btn_apply:hover {
  background-color: var(--section-black);
}

@media (max-width: 767px) {
  .input_label_field_container > label {
    font-size: 12px;
  }
  .input_label_field_container > input {
    font-size: 10px;
    height: 25px;
    padding: 3px;
  }
  .submit_btn_apply {
    margin: 0 auto;
    background: rgba(27, 94, 32, 1);
    font-family: poppinsSemiBold;
    color: var(--theme-white);
    height: 30px;
    border: 0;
    font-size: 12px;
    width: 30%;
    border-radius: 3px;
    transition: 1s;
  }
}

@media (min-width: 767px) and (max-width: 991px) {
  .input_label_field_container > label {
    font-size: 13px;
  }
  .input_label_field_container > input {
    font-size: 12px;
    height: 25px;
    padding: 3px;
  }
  .submit_btn_apply {
    margin: 0 auto;
    background: rgba(27, 94, 32, 1);
    font-family: poppinsSemiBold;
    color: var(--theme-white);
    height: 30px;
    border: 0;
    font-size: 13px;
    width: 30%;
    border-radius: 3px;
    transition: 1s;
  }
}
