.bg_section_container1 {
  /* background: url(../../assets/development_bg.svg) no-repeat fixed center; */
  background-image: url("../../assets/work/heroWorkSectiion2.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: top;
  width: 100%;
  /* max-width: 1400px; */
  margin: 0 auto;
  min-height: 100vh;
  position: relative;
}

.heading_hero_section_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.green_container_heading {
  padding: 25px;
  /* background: var(--theme-green); */
  display: inline-flex;

  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 45%,
    rgba(27, 94, 32, 1) 41%
  );
}
.work_title_text_container {
  position: relative;
}
.our_work_title_text {
  font-family: OswaldSemiBold;
  color: var(--theme-white);
  font-size: 70px;
  line-height: 80px;
}

@media (max-width: 299px) {
  .green_container_heading {
    padding: 15px;
  }
  .our_work_title_text {
    font-size: 30px;
    line-height: 35px;
  }
  .bg_section_container1 {
    min-height: 50vh;
    position: relative;
  }
  .heading_hero_section_container {
    width: 100%;
    text-align: center;
  }
}

@media (min-width: 300px) and (max-width: 399px) {
  .our_work_title_text {
    font-size: 30px;
    line-height: 35px;
  }
  .green_container_heading {
    padding: 15px;
  }
  .bg_section_container1 {
    min-height: 50vh;
    position: relative;
  }
  .heading_hero_section_container {
    width: 100%;
    text-align: center;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .our_work_title_text {
    font-size: 30px;
    line-height: 35px;
  }
  .green_container_heading {
    padding: 15px;
  }
  .bg_section_container1 {
    min-height: 60vh;
    position: relative;
  }
  .heading_hero_section_container {
    width: 100%;
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .our_work_title_text {
    font-size: 50px;
    line-height: 58px;
  }
  .green_container_heading {
    padding: 20px;
  }
  .bg_section_container1 {
    min-height: 70vh;
    position: relative;
  }
  .heading_hero_section_container {
    width: 100%;
    text-align: center;
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  .our_work_title_text {
    font-size: 55px;
    line-height: 65px;
  }
  .bg_section_container1 {
    min-height: 100vh;
    position: relative;
  }
  .heading_hero_section_container {
    width: 100%;
    text-align: center;
  }
  .green_container_heading {
    padding: 25px;
  }
}
