.green_container_heading_projects {
  /* background: var(--theme-green); */
  display: inline-flex;
  padding: 25px;
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 50%,
    rgba(27, 94, 32, 1) 34%
  );
}

@media (max-width: 299px) {
  .green_container_heading_projects {
    padding: 15px;
  }
}

@media (min-width: 300px) and (max-width: 399px) {
  .green_container_heading_projects {
    padding: 15px;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .green_container_heading_projects {
    padding: 15px;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .green_container_heading_projects {
    padding: 20px;
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  .green_container_heading_projects {
    padding: 25px;
  }
}
