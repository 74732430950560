.fix_btn_container {
  position: fixed;
  top: 50%;
  z-index: 10;
  background-color: #fff;
  border: 1px solid var(--theme-green);
  border-radius: 0 40px 40px 0;
  padding: 10px 15px;
}
.fix_text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: PoppinsSemiBold;
  gap: 10px;
  font-size: 16px;
}
.fix_text > svg {
  font-size: 20px;
}
.form_modal_container {
  margin: 10px 0 26px;
}
.right_fix_btn {
  position: fixed;
  bottom: 10%;
  z-index: 10;
  right: 5%;
  width: 50px;
}

@media (max-width: 767px) {
  .right_fix_btn {
    position: fixed;
    bottom: 10%;
    z-index: 10;
    right: 5%;
    width: 40px;
  }
}

@media (min-width: 768px) {
  .form_modal_container {
    margin: 10px 0 26px;
  }
  .right_fix_btn {
    position: fixed;
    bottom: 10%;
    z-index: 10;
    right: 5%;
    width: 45px;
  }
}

@media (min-width: 991px) {
}

@media (min-width: 1200px) {
  .form_modal_container {
    margin: 10px 0 26px;
  }
  .right_fix_btn {
    position: fixed;
    bottom: 10%;
    z-index: 10;
    right: 5%;
    width: 50px;
  }
}

@media (min-width: 1400px) {
  .form_modal_container {
    margin: 10px 0 30px;
  }
  .right_fix_btn {
    position: fixed;
    bottom: 10%;
    z-index: 10;
    right: 5%;
    width: 60px;
  }
}
