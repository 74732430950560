.get_in_touch_main_container {
  background-color: var(--theme-white);
}
.get_in_touch_under_main_container {
  margin: 0 auto;
  padding: 60px 0;
}
.title_of_get_in_text {
  margin: 0;
  font-family: "OswaldSemiBold";
  font-size: 60px;
  line-height: 60px;
}
.get_in_touch_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 55px 0;
  gap: 20px;
}
.get_in_touch_left_container {
  width: 50%;
}
.get_in_touch_img_container {
}
.get_in_touch_img_container > img {
  width: 100%;
}
.get_in_touch_right_container {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.get_in_touch_content {
  margin: -10px 0 0 0;
  font-family: "PoppinsMed";
  font-size: 20px;
  line-height: 29px;
  color: var(--section-black);
}
.get_in_touch_right_title {
  font-size: 29px;
  font-family: "OswaldReg";
  letter-spacing: 1px;
  color: var(--section-black);
}
.img_social_container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.img_social_container > a {
  text-decoration: none;
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 21px;
  gap: 10px;
  font-family: PoppinsSemiBold;
}
.mail_icon {
  font-size: 40px;
}

@media (max-width: 299px) {
  .get_in_touch_under_main_container {
    margin: 0 auto;
    padding: 30px 0;
    width: 94%;
  }
  .get_in_touch_left_container,
  .get_in_touch_right_container {
    width: 100%;
  }
  .get_in_touch_content {
    margin: -14px 0 0 0;
    font-size: 14px;
    line-height: 20px;
  }
  .get_in_touch_right_title {
    font-size: 20px;
  }
  .img_social_container > a {
    margin: 0;
    display: flex;
    align-items: center;
    font-size: 15px;
    gap: 2px;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .mail_icon {
    font-size: 23px;
  }

  .title_of_get_in_text {
    margin: 0;

    font-size: 27px;
    text-align: center;
    line-height: 38px;
  }
  .get_in_touch_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 25px 0;
    gap: 14px;
  }
}

@media (min-width: 300px) and (max-width: 399px) {
  .get_in_touch_under_main_container {
    margin: 0 auto;
    padding: 30px 0;
    width: 290px;
  }
  .get_in_touch_left_container,
  .get_in_touch_right_container {
    width: 100%;
  }
  .get_in_touch_content {
    margin: -14px 0 0 0;
    font-size: 14px;
    line-height: 20px;
  }
  .get_in_touch_right_title {
    font-size: 20px;
  }
  .img_social_container > a {
    margin: 0;
    display: flex;
    align-items: center;
    font-size: 15px;
    gap: 8px;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .mail_icon {
    font-size: 23px;
  }

  .title_of_get_in_text {
    margin: 0;

    font-size: 27px;
    text-align: center;
    line-height: 38px;
  }
  .get_in_touch_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 25px 0;
    gap: 14px;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .get_in_touch_under_main_container {
    margin: 0 auto;
    padding: 30px 0;
    width: 390px;
  }
  .get_in_touch_left_container,
  .get_in_touch_right_container {
    width: 100%;
  }
  .get_in_touch_content {
    margin: -14px 0 0 0;
    font-size: 16px;
    line-height: 23px;
  }
  .get_in_touch_right_title {
    font-size: 25px;
  }
  .img_social_container > a {
    margin: 0;
    display: flex;
    align-items: center;
    font-size: 19px;
    gap: 8px;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .mail_icon {
    font-size: 30px;
  }

  .title_of_get_in_text {
    margin: 0;

    font-size: 32px;
    text-align: center;
    line-height: 38px;
  }
  .get_in_touch_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 25px 0;
    gap: 14px;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .get_in_touch_under_main_container {
    width: 700px;
  }
  .get_in_touch_under_main_container {
    margin: 0 auto;
    padding: 35px 0;
  }
  .title_of_get_in_text {
    font-size: 40px;
    line-height: 42px;
  }
  .get_in_touch_content {
    margin: -10px 0 0 0;

    font-size: 15px;
    line-height: 20px;
  }
  .get_in_touch_right_title {
    font-size: 23px;

    letter-spacing: 0.5px;
  }
  .get_in_touch_right_container {
    gap: 10px;
  }
  .img_social_container > a {
    font-size: 15px;
  }
  .mail_icon {
    font-size: 24px;
  }
  .get_in_touch_container {
    margin: 30px 0 20px;
    gap: 15px;
  }
  .img_social_container {
    gap: 2px;
  }
}
@media (min-width: 991px) and (max-width: 1199px) {
  .get_in_touch_under_main_container {
    width: 930px;
  }
  .get_in_touch_under_main_container {
    margin: 0 auto;
    padding: 35px 0;
  }
  .title_of_get_in_text {
    font-size: 42px;
    line-height: 43px;
  }
  .get_in_touch_content {
    margin: -10px 0 0 0;
    font-size: 18px;
    line-height: 26px;
  }
  .get_in_touch_right_title {
    font-size: 25px;
    letter-spacing: 1px;
  }
  .get_in_touch_right_container {
    gap: 10px;
  }
  .img_social_container > a {
    font-size: 18px;
  }
  .mail_icon {
    font-size: 26px;
  }
  .get_in_touch_container {
    margin: 30px 0 20px;
    gap: 15px;
  }
  .img_social_container {
    gap: 2px;
  }
}

@media (min-width: 1200px) {
  .get_in_touch_under_main_container {
    width: 1100px;
  }
}

@media (min-width: 1400px) {
  .get_in_touch_under_main_container {
    width: 1300px;
  }
  .get_in_touch_right_title {
    font-size: 33px;

    letter-spacing: 1px;
  }
  .get_in_touch_content {
    margin: -10px 0 0 0;
    font-size: 24px;
    line-height: 34px;
  }
  .img_social_container > a {
    font-size: 24px;
    gap: 8px;
  }
  .mail_icon {
    font-size: 35px;
  }
  .img_social_container {
    display: flex;
    flex-direction: column;
    gap: 11px;
  }
}
