.career_main_container {
  background-image: url("../../assets/career/career_bg.jpg");
  background-color: #ebebeb;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-origin: border-box;
  width: 100%;
  background-position: top;
}
.career_under_main_container {
  margin: 0 auto;
  padding: 30px 0;
}
.career_detail_flex_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.career_left_details_container {
  width: 50%;
  display: flex;
}
.career_img_container {
  width: 100%;
}
.career_img_container > img {
  width: 100%;
}
.career_right_details_container {
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 15px;
}
.career_title_text {
  font-family: "OswaldSemiBold";
  font-size: 28px;
  line-height: 36px;
}
.career_extra_content_text {
  font-family: "PoppinsRegular";

  font-size: 21px;
}
.position_see_btn {
  background: var(--theme-green);
  border: 0;
  color: var(--theme-white);
  width: 150px;
  height: 35px;
  font-family: "PoppinsLight";
  text-transform: capitalize;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
}

@media (max-width: 299px) {
  .position_see_btn {
    width: 57%;
    height: 25px;
    font-size: 10px;
  }
  .career_main_container {
    background-position: right;
  }
  .career_detail_flex_container {
    align-items: center;
    flex-direction: column;
  }
  .career_left_details_container,
  .career_right_details_container {
    width: 100%;
  }
  .career_under_main_container {
    width: 94%;
  }
  .career_title_text {
    font-size: 20px;
    line-height: 24px;
  }
  .career_extra_content_text {
    font-size: 13px;
    line-height: 18px;
  }
  .position_see_btn {
    width: 64%;
    height: 30px;
    font-size: 12px;
  }
}

@media (min-width: 299px) and (max-width: 399px) {
  .career_main_container {
    background-position: right;
  }
  .career_detail_flex_container {
    align-items: center;
    flex-direction: column;
  }
  .career_left_details_container,
  .career_right_details_container {
    width: 100%;
  }
  .career_under_main_container {
    width: 290px;
  }
  .career_title_text {
    font-size: 20px;
    line-height: 24px;
  }
  .career_extra_content_text {
    font-size: 13px;
    line-height: 18px;
  }
  .position_see_btn {
    width: 50%;
    height: 34px;
    font-size: 13px;
  }
}

@media (min-width: 399px) and (max-width: 767px) {
  .career_main_container {
    background-position: right;
  }
  .career_detail_flex_container {
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
  .career_left_details_container,
  .career_right_details_container {
    width: 100%;
  }
  .career_under_main_container {
    width: 390px;
  }
  .career_title_text {
    font-size: 20px;
    line-height: 24px;
  }
  .career_extra_content_text {
    font-size: 15px;
    line-height: 20px;
  }
  .position_see_btn {
    width: 40%;
    height: 34px;
    font-size: 13px;
  }
}

@media (min-width: 767px) {
  .career_main_container {
    background-position: top;
  }
  .career_under_main_container {
    margin: 0 auto;
    padding: 30px 0;
    width: 700px;
  }
  .career_detail_flex_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .career_left_details_container {
    width: 50%;
    display: flex;
  }
  .career_img_container {
    width: 100%;
  }
  .career_img_container > img {
    width: 100%;
  }
  .career_right_details_container {
    width: 50%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    gap: 15px;
  }
  .career_title_text {
    font-family: "OswaldSemiBold";
    font-size: 23px;
    line-height: 30px;
  }
  .career_extra_content_text {
    font-family: "PoppinsRegular";
    font-size: 16px;
    line-height: 23px;
  }
  .position_see_btn {
    background: var(--theme-green);
    border: 0;
    color: var(--theme-white);
    width: 150px;
    height: 35px;
    font-family: "PoppinsLight";
    text-transform: capitalize;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
  }
}

@media (min-width: 990px) {
  .career_main_container {
    background-position: top;
  }
  .career_under_main_container {
    margin: 0 auto;
    padding: 30px 0;
    width: 930px;
  }
  .career_detail_flex_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .career_left_details_container {
    width: 50%;
    display: flex;
  }
  .career_img_container {
    width: 100%;
  }
  .career_img_container > img {
    width: 100%;
  }
  .career_right_details_container {
    width: 50%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    gap: 15px;
  }
  .career_title_text {
    font-family: "OswaldSemiBold";
    font-size: 23px;
    line-height: 30px;
  }
  .career_extra_content_text {
    font-family: "PoppinsRegular";
    font-size: 16px;
    line-height: 23px;
  }
  .position_see_btn {
    background: var(--theme-green);
    border: 0;
    color: var(--theme-white);
    width: 150px;
    height: 35px;
    font-family: "PoppinsLight";
    text-transform: capitalize;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
  }
}

@media (min-width: 1200px) {
  .career_under_main_container {
    width: 1100px;
  }
  .career_extra_content_text {
    font-size: 18px;
    line-height: 25px;
  }
  .career_title_text {
    font-size: 26px;
    line-height: 32px;
  }
}

@media (min-width: 1400px) {
  .career_under_main_container {
    width: 1300px;
  }
  .career_extra_content_text {
    font-size: 22px;
    line-height: 32px;
  }
  .career_title_text {
    font-size: 34px;
    line-height: 40px;
  }
  .position_see_btn {
    width: 160px;
    height: 40px;

    font-size: 17px;
  }
}
