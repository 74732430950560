.overlay_styles1 {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}
.modal_styles1 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 2% 2% 0 2%;
  width: 50%;
  z-index: 1000;
  max-height: 650px;
  overflow-y: scroll;
  border-radius: 8px;
}
.close_btn_headertext_container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.close_btn_dialog {
  border: none;
  /* background: #01bc82; */
  padding: 1%;
  border-radius: 50%;
  text-align: center;
  display: flex;
  outline: none;
  margin: 0 0 2% auto;
  align-items: center;
  flex-direction: row;
  height: 24px;
  width: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .close_btn_dialog:hover {
    background-color: var(--theme-blue)
  } */
.md_close_btn_icon1 {
  font-size: 20px;
  color: var(--theme-blue);
}
.edit_dialog_header {
  font-family: OswaldBold;
  font-size: 28px;
  color: var(--theme-blue);
}
.md_close_btn_icon1:hover {
  color: #fff;
  font-size: 21px;
}

@media screen and (max-width: 767px) {
  .edit_dialog_header {
    margin: 0;
    font-size: 16px;
    font-weight: IBMMed;
  }
  .close_btn_headertext_container {
    align-items: center;
  }
  .close_btn_dialog {
    height: 30px;
    width: 30px;
    padding: 2%;
    margin: 0 0 0% auto;
  }
  .modal_styles1 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 7% 5% 4% 5%;
    width: 90%;
    z-index: 1000;
    max-height: 480px;
    overflow-y: scroll;
    border-radius: 4px;
  }
  .overlay_styles1 {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
  }
}

@media screen and (min-width: 768px) {
  .edit_dialog_header {
    margin: 0;

    font-size: 17px;
  }
  .close_btn_headertext_container {
    align-items: center;
  }
  .close_btn_dialog {
    height: 30px;
    width: 30px;
    padding: 1.5%;
    margin: 0 0 0% auto;
  }
  .modal_styles1 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 2% 2% 0 2%;
    width: 740;
    z-index: 1000;
    max-height: 400px;
    overflow-y: auto;
    border-radius: 8px;
  }
  .overlay_styles1 {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
  }
}

@media screen and (min-width: 991px) {
  .edit_dialog_header {
    margin: 0;

    font-size: 21px;
  }
  .close_btn_headertext_container {
    align-items: center;
  }
  .close_btn_dialog {
    height: 35px;
    width: 35px;
    padding: 1%;
    margin: 0 0 0% auto;
  }
  .modal_styles1 {
    width: 940;
    max-height: 470px;
  }
}

@media screen and (min-width: 1200px) {
  .modal_styles1 {
    width: 850px;
    max-height: 590px;
    overflow-y: auto;
  }
  .md_close_btn_icon1 {
    font-size: 20px;
  }
  .close_btn_dialog {
    border: none;
    /* background: #01bc82; */
    padding: 0;
    border-radius: 50%;
    text-align: center;
    display: flex;
    outline: none;
    margin: 0 0 2% auto;
    align-items: center;
    flex-direction: row;
    height: 35px;
    width: 35px;
  }
}

@media (min-width: 1400px) {
  .modal_styles1 {
    width: 900px;
    max-height: 590px;
    overflow-y: auto;
  }
  .edit_dialog_header {
    font-size: 24px;
  }
}
