.main_work_flow_container {
  background: linear-gradient(180deg, #e5e5e5 0%, rgb(255 255 255) 100%);
}
.work_flow_under_main_container {
}
.workflow_text_container {
}
.work_flow_text_heading {
  text-align: center;
  font-family: OswaldSemiBold;
  font-size: 60px;
  text-transform: uppercase;
  color: var(--section-black);
}
.highlighted_title_text {
  font-family: OswaldSemiBold;
  font-size: 60px;
  text-transform: uppercase;
  color: var(--theme-green);
}
.workflow_body_container {
  padding: 30px 0;
}
.same_work_flow_container_style {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.workflow_icon_container {
  width: 80px;
  height: 80px;
  display: flex;
  padding: 15px;
  border-radius: 50%;
  background-color: var(--theme-green);
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.workflow_icon_container1 {
  width: 80px;
  height: 80px;
  display: flex;
  padding: 15px;
  border-radius: 50%;
  background-color: var(--theme-green);
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.workflow_icon_container > img {
  width: 40px;
}
.work_flow_text {
  font-family: PoppinsSemiBold;
  color: var(--theme-green);
  font-size: 20px;
  line-height: 25px;
  margin: 12px 0;
}
.work_design_img {
  width: 26px;
}
.workflow_one_container {
}

.workflow_two_container {
  position: relative;
  top: 95px;
  left: -94px;
}
.workflow_three_container {
  position: relative;
  top: 198px;
  left: -180px;
}
.workflow_four_container {
  position: relative;
  top: 145px;
  left: 70%;
}
.workflow_fifth_container {
  position: relative;
  top: 155px;
  left: 61%;
}
.workflow_six_container {
  position: relative;
  top: 32px;
  right: 14%;
}
.workflow_seven_container {
  position: relative;
  bottom: 270px;
  right: -6%;
}
.workflow_eight_container {
  position: relative;
  bottom: -71px;
  right: 6%;
}
.arrow_img_1 {
  position: relative;
  top: 46px;
  left: -27px;
}
.arrow_img_2 {
  position: relative;
  top: 136px;
  left: -146px;
}
.arrow_img_3 {
  top: 85px;
  position: relative;
  left: 72%;
  width: 18%;
}
.arrow_img_4 {
  top: 111px;
  position: relative;
  left: 80%;
}
.arrow_img_5 {
  position: relative;
  top: 289px;
  left: 24%;
}
.arrow_img_6 {
  position: relative;
  top: 11px;
  right: 52%;
}
.arrow_img_7 {
  position: relative;
  top: 60px;
  right: 70px;
}
@media (max-width: 299px) {
  .work_flow_under_main_container {
    padding: 35px 0;
    width: 100%;
    margin: 0 auto;
  }
}
@media (min-width: 300px) and (max-width: 399px) {
  .work_flow_under_main_container {
    padding: 35px 0;
    width: 280px;
    margin: 0 auto;
  }
}
@media (min-width: 400px) and (max-width: 767px) {
  .work_flow_under_main_container {
    padding: 35px 0;
    width: 380px;
    margin: 0 auto;
  }
}

@media (max-width: 767px) {
  .work_flow_under_main_container {
    padding: 35px 0;
  }
  .work_flow_text_heading,
  .highlighted_title_text {
    font-size: 30px;
  }
  .arrow_img_1,
  .arrow_img_2,
  .arrow_img_3,
  .arrow_img_4,
  .arrow_img_5,
  .arrow_img_6,
  .arrow_img_7 {
    display: none;
  }
  .workflow_one_container,
  .workflow_two_container,
  .workflow_three_container,
  .workflow_four_container,
  .workflow_fifth_container,
  .workflow_six_container,
  .workflow_seven_container,
  .workflow_eight_container {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .workflow_body_container,
  .workflow_under_body_one_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .workflow_icon_container {
    width: 65px;
    height: 65px;

    padding: 16px;
  }
  .workflow_icon_container > img {
    width: 26px;
  }
  .workflow_icon_container1 {
    width: 65px;
    height: 65px;

    padding: 22px;
  }
  .work_flow_text {
    font-size: 17px;
    line-height: 25px;
    margin: 18px 0;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .workflow_icon_container {
    width: 60px;
    height: 60px;
    display: flex;
    padding: 17px;
  }
  .workflow_icon_container1 {
    width: 60px;
    height: 60px;
    display: flex;
    padding: 20px;
  }
  .work_flow_text_heading,
  .highlighted_title_text {
    font-size: 40px;
  }
  .work_flow_text {
    font-size: 14px;
    line-height: 18px;
    margin: 8px 0;
  }
  .work_flow_under_main_container {
    width: 750px;
    margin: 0 auto;
    padding: 35px 0;
  }
  .arrow_img_1 {
    position: relative;
    top: 17px;
    left: -23px;
    width: 23%;
  }
  .arrow_img_2 {
    position: relative;
    top: 72px;
    left: -91px;
    width: 23%;
  }

  .arrow_img_3 {
    top: 47px;
    position: relative;
    left: 70%;
    width: 20%;
  }
  .arrow_img_4 {
    top: 87px;
    position: relative;
    left: 81%;
    width: 12%;
  }
  .arrow_img_5 {
    position: relative;
    top: 73px;
    left: 23%;
    width: 24%;
  }
  .arrow_img_5 {
    position: relative;
    top: 203px;
    left: 28%;
    width: 28%;
  }
  .arrow_img_6 {
    position: relative;
    top: 12px;
    right: 41%;
    width: 28%;
  }
  .arrow_img_7 {
    position: relative;
    top: 51px;
    right: -11%;
    width: 19%;
  }
  .workflow_two_container {
    position: relative;
    top: 52px;
    left: -62px;
  }

  .workflow_three_container {
    position: relative;
    top: 120px;
    left: -116px;
  }

  .workflow_four_container {
    position: relative;
    top: 92px;
    left: 68%;
  }
  .workflow_fifth_container {
    position: relative;
    top: 116px;
    left: 60%;
  }
  .workflow_six_container {
    position: relative;
    top: 38px;
    right: 7%;
  }
  .workflow_seven_container {
    position: relative;
    bottom: -55px;
    right: 75%;
  }
  .workflow_eight_container {
    position: relative;
    bottom: -61px;
    right: -11%;
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  .main_work_flow_container {
    padding: 0 0 75px 0;
  }
  .workflow_icon_container {
    width: 60px;
    height: 60px;
    display: flex;
    padding: 16px;
  }
  .workflow_icon_container1 {
    width: 60px;
    height: 60px;
    display: flex;
    padding: 20px;
  }
  .work_flow_text_heading,
  .highlighted_title_text {
    font-size: 43px;
  }
  .work_flow_text {
    font-size: 14px;
    line-height: 18px;
    margin: 8px 0;
  }
  .work_flow_under_main_container {
    width: 930px;
    margin: 0 auto;
    padding: 40px 0;
  }
  .arrow_img_1 {
    position: relative;
    top: 40px;
    left: -10px;

    width: 22%;
  }
  .arrow_img_2 {
    position: relative;
    top: 111px;
    left: -90px;
    width: 22%;
  }

  .arrow_img_3 {
    top: 205px;
    position: relative;
    left: -11%;
    width: 22%;
  }
  .arrow_img_4 {
    top: 151px;
    position: relative;
    left: 78%;
    width: 11%;
  }
  .arrow_img_5 {
    position: relative;
    top: 73px;
    left: 23%;
    width: 20%;
  }
  .arrow_img_5 {
    position: relative;
    top: 270px;
    left: 30%;
    width: 25%;
  }
  .arrow_img_6 {
    position: relative;
    top: 68px;
    right: 30%;
    width: 25%;
  }
  .arrow_img_7 {
    position: relative;
    top: 108px;
    right: -16%;
    width: 15%;
  }
  .workflow_two_container {
    position: relative;
    top: 80px;
    left: -58px;
  }

  .workflow_three_container {
    position: relative;
    top: 156px;
    left: -111px;
  }

  .workflow_four_container {
    position: relative;
    top: 157px;
    left: 85%;
  }
  .workflow_fifth_container {
    position: relative;
    top: 172px;
    left: 59%;
  }
  .workflow_six_container {
    position: relative;
    top: 93px;
    right: 0%;
  }
  .workflow_seven_container {
    position: relative;
    bottom: -113px;
    right: 60%;
  }
  .workflow_eight_container {
    position: relative;
    bottom: -111px;
    right: -17%;
  }
}
@media (min-width: 1200px) {
  .work_flow_text_heading {
    line-height: 65px;
  }

  .workflow_text_container {
    width: 80%;
    margin: 0 auto;
  }
  .work_flow_under_main_container {
    width: 1100px;
    margin: 0 auto;
    padding: 70px 0;
  }
}
/* 
@media (min-width: 1400px) {
  .work_flow_under_main_container {
    width: 1300px;
    margin: 0 auto;
    padding: 70px 0;
  }
} */
