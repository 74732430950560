.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 62px;
}

.navbar .menu-items {
  display: flex;
}

.navbar .nav-container li {
  list-style: none;
}

.nav-container {
  display: flex;
  position: relative;
  height: 60px;

  align-items: center;
  flex-direction: row;
}
.call_img_container {
  display: none;
}
.navbar_logo {
  width: 84px;
}
.nav-container .checkbox {
  display: block;
  height: 32px;
  width: 32px;
  display: none;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
}

.nav-container .hamburger-lines {
  display: none;
  height: 26px;
  width: 32px;
}

.nav-container .hamburger-lines .line {
  display: block;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background: #0e2431;
}

.nav-container .hamburger-lines .line1 {
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
}

.nav-container .hamburger-lines .line2 {
  transition: transform 0.2s ease-in-out;
}

.nav-container .hamburger-lines .line3 {
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
}

.navbar .menu-items {
  /* transform: translate(-150%); */
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  gap: 15px;
}

.navbar .menu-items li {
  display: block;
  font-family: "OswaldMed";
  color: #d0d0d0;
  letter-spacing: 1px;
  padding: 0 25px 0 0;

  border-radius: 3px;
  text-transform: uppercase;
  padding: 5px 10px 2px;
  font-size: 20px;
  transition: var(--transition);
}
/* .navbar .menu-items li:hover {
  border-bottom: 5px solid var(--theme-green);
  color: #ffffff9c;
  letter-spacing: 0.3px;
} */
.navbar .menu-items li:hover,
.active,
.navbar .menu-items li:visited {
  border-bottom: 5px solid var(--theme-green);
  color: #ffffff9c;
  text-shadow: 1px 1px 2px #111;
  letter-spacing: 0.3px;
  font-weight: OswaldBold;
}
.logo {
}
.call_us_text {
  background: var(--theme-green);
  color: var(--theme-white);
  display: block;
  margin: 0 0 0 25px;
  padding: 10px;
  font-size: 20px;
  font-family: "OswaldMed";
  letter-spacing: 1px;
  border-radius: 3px;

  transition: var(--transition);
}
.call_us_text:hover {
  background: var(--section-black);
  color: var(--theme-white);
}

/* .nav-container input[type="checkbox"]:checked ~ .menu-items {
  transform: translateX();
} */

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
  transform: rotate(45deg);
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
  transform: scaleY(0);
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
  transform: rotate(-45deg);
}

.nav-container input[type="checkbox"]:checked ~ .logo {
  display: none;
}
.close_btn_under_menu_items {
  display: none;
}

@media (max-width: 767px) {
  .close_btn_under_menu_items {
    display: flex;
    margin: 0 0 0 auto;
    margin-right: 10px;
  }
  .close_white_color {
    color: #fff;
    font-size: 32px;
  }
  .navbar_logo > img {
    width: 80px;
  }
  .call_img_container {
    position: absolute;
    top: 18px;
    right: 55px;
    display: block;
  }
  .call_img_container > img {
    width: 35px;
  }
  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 62px;
  }

  .navbar .menu-items {
    display: flex;
  }

  .navbar .nav-container li {
    list-style: none;
  }

  .menu-items li a {
    text-decoration: none;
    color: var(--theme-white);
  }

  .navbar .nav-container a:hover {
    color: var(--theme-green);
  }

  .nav-container {
    display: block;
    position: relative;
    height: 60px;
    padding: 10px 0;
  }

  .nav-container .checkbox {
    position: absolute;
    display: block;
    height: 40px;
    width: 40px;
    top: 15px;
    right: 11px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
  }
  .navbar .menu-items li:hover {
    border-bottom: 5px solid #1b5e2000;
  }
  .nav-container .hamburger-lines {
    height: 23px;
    width: 29px;
    position: absolute;
    top: 23px;
    right: 11px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .nav-container .hamburger-lines .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: #0e2431;
  }

  .nav-container .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
  }

  .nav-container .hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
  }

  .nav-container .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
  }
  .call_us_text {
    display: none;
  }

  .navbar .menu-items {
    background-color: var(--section-black);
    padding-top: 25px;
    margin: 9px 0 0 0;
    position: relative;
    z-index: 1;
    position: fixed;
    height: 100vh;
    width: 100%;
    transform: translate(-150%);
    display: flex;
    flex-direction: column;
    transition: transform 0.5s ease-in-out;
    text-align: center;
  }

  .navbar .menu-items li {
  }

  .logo {
    position: absolute;
    top: 5px;
    right: 15px;
    font-size: 1.2rem;
    color: #0e2431;
  }

  .nav-container input[type="checkbox"]:checked ~ .menu-items {
    transform: translateX(0);
  }

  .nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
    transform: rotate(45deg);
  }

  .nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
    transform: scaleY(0);
  }

  .nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
    transform: rotate(-45deg);
  }

  .nav-container input[type="checkbox"]:checked ~ .logo {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar_logo {
    width: 80px;
    margin: 12px 0 0 0;
  }
  .call_us_text {
    margin: 0;
    padding: 7px 11px;
    font-size: 13px;
    letter-spacing: 0.8px;
  }
  .navbar .menu-items li {
    padding: 0 25px 0 0;

    padding: 5px 3px 2px;
    font-size: 14px;
  }
}

@media (min-width: 991px) {
  .navbar_logo {
    width: 111px;
  }
  .call_us_text {
    margin: 0 0 0 0px;
    padding: 10px;
    font-size: 14px;

    letter-spacing: 0.8px;
  }
  .navbar .menu-items li {
    padding: 0 25px 0 0;

    padding: 5px 3px 2px;
    font-size: 16px;
  }
}

@media (min-width: 1200px) {
  .navbar_logo {
    width: 130px;
  }
  .call_us_text {
    font-size: 19px;
    letter-spacing: 0.8px;
  }
  .navbar .menu-items li {
    padding: 0 25px 0 0;

    padding: 5px 3px 2px;
    font-size: 19px;
  }
  .call_us_text {
    margin: 0 0 0 18px;
    padding: 15px 16px;
  }
}

@media (min-width: 1400px) {
  .navbar_logo {
    width: 150px;
  }
}
